import React, { useState, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'
import CreateOverride from './CreateOverride'
import { useFileUpload } from '../hooks/useFileUpload'
import { useOverrideManagement } from '../hooks/useOverrideManagement'
import {
  ExclamationCircleFilled,
  WarningFilled,
  ClusterOutlined,
  CopyFilled,
  SettingOutlined,
  TableOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { FeeValidationErrorTypes, FEE_MGMT_COLS_READABLE } from '../constants/Validation'
import useStepper from '../hooks/useStepper'
import { OverrideProvider } from '../contexts/OverrideContext'

const OVERRIDE_STEPS = [
  {
    title: 'Configuration',
    description: 'Set up override details',
    icon: SettingOutlined,
  },
  {
    title: 'Data Review',
    description: 'Review and modify data',
    icon: TableOutlined,
  },
  {
    title: 'Summary',
    description: 'Override created successfully',
    icon: CheckCircleOutlined,
  },
]

const CreateOverrideManager = () => {
  const { type } = useParams()
  const [fileHeaders, setFileHeaders] = useState(new Set())
  const [gridData, setGridData] = useState(null)
  const [validationSummary, setValidationSummary] = useState(null)
  const { currentStep, nextStep, previousStep, goToStep } = useStepper(OVERRIDE_STEPS) // eslint-disable-line no-unused-vars
  const [overrideName, setOverrideName] = useState('')
  const [fileName, setFileName] = useState('')
  const [validationErrors, setValidationErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false) // eslint-disable-line no-unused-vars

  // Define validation config
  const validationConfig = useMemo(() => {
    const baseConfig = {
      [FeeValidationErrorTypes.MISSING_VALUE]: {
        label: 'Missing Required',
        color: '#ff4d4f',
        icon: ExclamationCircleFilled,
        tooltipText: 'rows with missing required values',
      },
      [FeeValidationErrorTypes.INVALID_FORMAT]: {
        label: 'Invalid Format',
        color: '#faad14',
        icon: WarningFilled,
        tooltipText: 'rows with invalid format',
      },
      [FeeValidationErrorTypes.DUPLICATE_ROW]: {
        label: 'Duplicate Rows',
        color: '#722ed1',
        icon: CopyFilled,
        tooltipText: 'duplicate rows (automatically marked for deletion)',
      },
    }

    if (fileHeaders.has(FEE_MGMT_COLS_READABLE.LOCATION_TYPE)) {
      baseConfig[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE] = {
        label: 'Invalid Field',
        color: '#faad14',
        icon: WarningFilled,
        tooltipText: 'rows with fields not allowed for their location type',
      }
      baseConfig[FeeValidationErrorTypes.DUPLICATE_STATE] = {
        label: 'Duplicate State',
        color: '#1890ff',
        icon: ClusterOutlined,
        tooltipText: 'rows with duplicate states',
      }
      baseConfig[FeeValidationErrorTypes.DUPLICATE_COUNTY] = {
        label: 'Duplicate County',
        color: '#1890ff',
        icon: ClusterOutlined,
        tooltipText: 'rows with duplicate counties',
      }
      baseConfig[FeeValidationErrorTypes.DUPLICATE_ZIP] = {
        label: 'Duplicate ZIP',
        color: '#1890ff',
        icon: ClusterOutlined,
        tooltipText: 'rows with duplicate ZIP codes',
      }
    }

    if (fileHeaders.has(FEE_MGMT_COLS_READABLE.VENDOR)) {
      baseConfig[FeeValidationErrorTypes.DUPLICATE_VENDOR] = {
        label: 'Duplicate Vendor',
        color: '#1890ff',
        icon: ClusterOutlined,
        tooltipText: 'rows with duplicate vendors',
      }
    }

    return baseConfig
  }, [fileHeaders])

  const { activeSection, selectedFields, sectionTitle, handleBack, handleFieldsChange } = useOverrideManagement()

  const { handleFileUpload } = useFileUpload({
    activeSection: type || activeSection,
    selectedFields,
    onDataProcessed: (data, summary) => {
      setGridData(data)
      setValidationSummary(summary)
    },
    setFileHeaders,
  })

  const handleFileUploadWrapper = useCallback(
    async (rows, metadata) => {
      try {
        setFileName(metadata.fileName)
        const result = await handleFileUpload(rows, metadata)
        if (result.validationErrors) {
          setValidationErrors(result.validationErrors)
        }
      } catch (error) {
        setValidationErrors(['Error processing file'])
      }
    },
    [handleFileUpload],
  )

  const handleNext = useCallback(() => {
    nextStep()
  }, [nextStep])

  const canProceedToDataReview = useMemo(() => {
    return !!(overrideName.trim() && selectedFields.length > 0 && fileName)
  }, [overrideName, selectedFields, fileName])

  const handleOverrideNameChange = useCallback((value) => {
    setOverrideName(value)
  }, [])

  const initialValues = useMemo(
    () => ({
      overrideName: overrideName,
      selectedFields: selectedFields,
    }),
    [overrideName, selectedFields],
  )

  const handleSave = useCallback(
    async (data) => {
      logFormattedMessage('Handling save', { data })
      try {
        // TODO: Implement save
        handleBack()
      } catch (error) {
        logFormattedMessage('Error saving', { error })
      }
    },
    [handleBack],
  )

  const overrideProps = useMemo(
    () => ({
      title: sectionTitle,
      type: type || activeSection,
      onBack: handleBack,
    }),
    [sectionTitle, type, activeSection, handleBack],
  )

  const fileHandlingProps = useMemo(
    () => ({
      onFileUpload: handleFileUploadWrapper,
      fileName,
      validationErrors,
      validationSummary,
      validationConfig,
    }),
    [handleFileUploadWrapper, fileName, validationErrors, validationSummary, validationConfig],
  )

  const formProps = useMemo(
    () => ({
      selectedFields,
      onFieldsChange: handleFieldsChange,
      overrideName,
      onOverrideNameChange: handleOverrideNameChange,
      initialValues,
      canProceedToDataReview,
    }),
    [selectedFields, handleFieldsChange, overrideName, handleOverrideNameChange, initialValues, canProceedToDataReview],
  )

  const stepperProps = useMemo(
    () => ({
      currentStep,
      onNext: handleNext,
      steps: OVERRIDE_STEPS,
    }),
    [currentStep, handleNext],
  )

  const gridProps = useMemo(
    () => ({
      gridData,
      isLoading,
      onSave: handleSave,
    }),
    [gridData, isLoading, handleSave],
  )

  const contextValue = useMemo(
    () => ({
      validationConfig,
      selectedFields,
      onFieldsChange: handleFieldsChange,
      onSave: handleSave,
    }),
    [validationConfig, selectedFields, handleFieldsChange, handleSave],
  )

  return (
    <Container fluid>
      <OverrideProvider value={contextValue}>
        <CreateOverride
          overrideProps={overrideProps}
          fileHandlingProps={fileHandlingProps}
          formProps={formProps}
          stepperProps={stepperProps}
          gridProps={gridProps}
        />
      </OverrideProvider>
    </Container>
  )
}

export default CreateOverrideManager
