import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Space, Tag, Card, Skeleton } from 'antd'
import {
  ArrowLeftOutlined,
  GoldOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import LoadingSpinner from './LoadingSpinner'
import GridManager from './GridManager'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'
import '../styles/ViewOverride.scss'
import { FeeValidationErrorTypes } from '../constants/Validation'
import { useGridState } from '../hooks/useGridState'

const { Title, Text } = Typography

const OVERRIDE_ICONS = {
  location: EnvironmentOutlined,
  vendor: TeamOutlined,
  vendorLocation: GoldOutlined,
}

const getFieldIcon = (field) => {
  if (field.toLowerCase() === 'fee') {
    return <DollarOutlined />
  }
  if (field.toLowerCase() === 'due date') {
    return <CalendarOutlined />
  }
  return null
}

const ViewOverride = ({ override, overrideData, isLoading, onBack }) => {
  const Icon = override?.overrideType ? OVERRIDE_ICONS[override.overrideType] : null

  const {
    gridProps: baseGridProps,
    headerDeleteActive,
    gridApiRef,
    handleToggleDelete,
    handleHeaderDeleteToggle,
    handleCellValueChanged,
    handleFilterChanged,
  } = useGridState()

  // Create validation config once
  const validationConfig = useMemo(
    () => ({
      required: [],
      optional: [],
      errorLabels: {
        [FeeValidationErrorTypes.MISSING_VALUE]: 'Missing Required Value',
        [FeeValidationErrorTypes.INVALID_FORMAT]: 'Invalid Format',
        [FeeValidationErrorTypes.DUPLICATE_ROW]: 'Duplicate Row',
        [FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE]: 'Invalid Field for Location Type',
        [FeeValidationErrorTypes.DUPLICATE_STATE]: 'Duplicate State',
        [FeeValidationErrorTypes.DUPLICATE_COUNTY]: 'Duplicate County',
        [FeeValidationErrorTypes.DUPLICATE_ZIP]: 'Duplicate Zip',
        [FeeValidationErrorTypes.DUPLICATE_VENDOR]: 'Duplicate Vendor',
      },
    }),
    [],
  )

  // Create column definitions once
  const columnDefs = useMemo(
    () => [
      { field: 'productName', headerName: 'Product Name' },
      { field: 'vendor', headerName: 'Vendor', hide: override?.overrideType === 'location' },
      { field: 'locationType', headerName: 'Location Type', hide: override?.overrideType === 'vendor' },
      { field: 'states', headerName: 'States', hide: override?.overrideType === 'vendor' },
      { field: 'counties', headerName: 'Counties', hide: override?.overrideType === 'vendor' },
      { field: 'zipCodes', headerName: 'Zip Codes', hide: override?.overrideType === 'vendor' },
      { field: 'fee', headerName: 'Fee' },
      { field: 'dueDate', headerName: 'Due Date' },
    ],
    [override?.overrideType],
  )

  // Create grid props only when necessary data changes
  const viewGridProps = useMemo(() => {
    logFormattedMessage('Attempting to create viewGridProps', {
      hasOverrideData: !!overrideData,
      overrideDataLength: overrideData?.length,
      isLoading,
    })

    if (!overrideData) {
      logFormattedMessage('Cannot create viewGridProps - no override data')
      return null
    }

    // Log the raw data coming in
    logFormattedMessage('ViewOverride raw data sample', {
      sampleData: overrideData.slice(0, 2),
      totalRows: overrideData.length,
    })

    // Map the data to grid format
    const mappedData = overrideData.map((item) => {
      const mapped = {
        rowNumber: item._id,
        productName: item.product_id,
        vendor: item.panelist_id,
        locationType: item.location_type,
        states: Array.isArray(item.states) ? item.states.join(', ') : '',
        counties: Array.isArray(item.counties) ? item.counties.join(', ') : '',
        zipCodes: Array.isArray(item.zips) ? item.zips.join(', ') : '',
        fee: item.override_data?.fee,
        dueDate: item.override_data?.due_date,
        validationErrors: {},
        userMarkedForDelete: false,
        isDuplicateRow: false,
      }

      // Log a sample of the mapped data
      if (item._id === overrideData[0]._id) {
        logFormattedMessage('ViewOverride data mapping example', {
          original: item,
          mapped,
        })
      }

      return mapped
    })

    logFormattedMessage('Creating grid props for ViewOverride', {
      overrideType: override?.overrideType,
      dataLength: mappedData.length,
      sampleMappedRow: mappedData[0],
      columnDefs: columnDefs.map((col) => ({
        field: col.field,
        headerName: col.headerName,
        hide: col.hide,
      })),
    })

    return {
      ...baseGridProps,
      rowData: mappedData,
      onToggleDelete: handleToggleDelete,
      onCellValueChanged: handleCellValueChanged,
      onFilterChanged: handleFilterChanged,
      gridApiRef,
      headerDeleteActive,
      onHeaderDeleteToggle: handleHeaderDeleteToggle,
      columnDefs,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      validationErrorTypes: FeeValidationErrorTypes,
      validationConfig,
    }
  }, [
    overrideData,
    baseGridProps,
    columnDefs,
    validationConfig,
    gridApiRef,
    handleToggleDelete,
    handleCellValueChanged,
    handleFilterChanged,
    handleHeaderDeleteToggle,
    headerDeleteActive,
    isLoading,
    override,
  ])

  // Log when grid props change
  useEffect(() => {
    logFormattedMessage('ViewOverride state', {
      hasViewGridProps: !!viewGridProps,
      isLoading,
      hasOverrideData: !!overrideData,
      overrideDataLength: overrideData?.length,
    })
  }, [viewGridProps, isLoading, overrideData])

  logFormattedMessage('ViewOverride rendering', {
    override,
    overrideData,
    isLoading,
  })

  if (!override && !isLoading) {
    logFormattedMessage('Override not found')
    return (
      <div className="view-override fee-management-container">
        <div className="header-section">
          <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={onBack} />
          <div>Override not found</div>
        </div>
      </div>
    )
  }

  return (
    <div className="view-override">
      <div className="view-override-header">
        <Button type="text" icon={<ArrowLeftOutlined />} onClick={onBack} className="back-button" />
        <Space direction="vertical" size={4}>
          <Space align="center" size={12}>
            {Icon && <Icon style={{ fontSize: '24px', color: '#1890ff' }} />}
            <Title level={2} style={{ margin: 0 }}>
              {override?.title}
            </Title>
          </Space>
          <Space size="middle">
            <Tag
              icon={override?.status === 'active' ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
              color={override?.status === 'active' ? 'success' : 'default'}
              className="override-tag"
            >
              {override?.status ? (
                override.status.charAt(0).toUpperCase() + override.status.slice(1)
              ) : (
                <Skeleton.Button active size="small" style={{ width: 60 }} />
              )}
            </Tag>
            <Tag color="blue" className="override-tag field-tag">
              {override?.overriddenFields ? (
                <>
                  {override.overriddenFields.map((field, index) => {
                    const icon = getFieldIcon(field)
                    return (
                      <React.Fragment key={field}>
                        {icon}
                        {field}
                        {index < override.overriddenFields.length - 1 ? ' & ' : ''}
                      </React.Fragment>
                    )
                  })}
                </>
              ) : (
                <Skeleton.Button active size="small" style={{ width: 80 }} />
              )}
            </Tag>
          </Space>
        </Space>
      </div>

      <Card className="override-details-card">
        <div className="details-grid">
          {isLoading ? (
            <>
              <Skeleton active paragraph={{ rows: 1 }} />
              <Skeleton active paragraph={{ rows: 1 }} />
              <Skeleton active paragraph={{ rows: 1 }} />
              <Skeleton active paragraph={{ rows: 1 }} />
            </>
          ) : (
            Object.entries(override?.details || {}).map(([key, value]) => (
              <div key={key} className="detail-item">
                <Text type="secondary">{key}</Text>
                <Text strong>{value}</Text>
              </div>
            ))
          )}
        </div>
      </Card>

      <div className="grid-section">
        {isLoading ? (
          <LoadingSpinner text="Fetching override details and data..." className="overlay" />
        ) : overrideData ? (
          <GridManager
            initialRowData={overrideData}
            validationSummary={{
              errorCounts: {},
              errorRows: {},
              totalErrorCount: 0,
              hasErrors: false,
            }}
            validationConfig={validationConfig}
            onSave={() => {}}
            totalErrors={0}
          />
        ) : (
          <LoadingSpinner text="Preparing grid data..." className="overlay" />
        )}
      </div>
    </div>
  )
}

ViewOverride.propTypes = {
  override: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['active', 'inactive']).isRequired,
    overrideType: PropTypes.oneOf(['location', 'vendor', 'vendorLocation']).isRequired,
    overriddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    details: PropTypes.object.isRequired,
  }),
  overrideData: PropTypes.array,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
}

export default ViewOverride
