import { useState, useCallback, useRef, useEffect } from 'react'
import { FEE_MGMT_COLUMNS } from '../constants/Validation'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'

export const useGridState = () => {
  const [gridProps, setGridProps] = useState(null)
  const [headerDeleteActive, setHeaderDeleteActive] = useState(false)
  const [filteredRows, setFilteredRows] = useState([])
  const [activeFilters, setActiveFilters] = useState([])
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(true)
  const gridApiRef = useRef(null)
  const originalDataRef = useRef(null)

  const handleToggleDelete = useCallback((rowNumbers, shouldMark) => {
    logFormattedMessage('handleToggleDelete called', { rowNumbers, shouldMark })

    setGridProps((prev) => {
      if (!prev?.rowData) {
        logFormattedMessage('handleToggleDelete: no rowData found', { prev })
        return prev
      }

      const updatedData = prev.rowData.map((row) =>
        rowNumbers.includes(row.rowNumber) ? { ...row, userMarkedForDelete: shouldMark } : row,
      )

      logFormattedMessage('handleToggleDelete: updating rows', {
        rowsUpdated: rowNumbers.length,
        totalRows: prev.rowData.length,
        markedForDelete: shouldMark,
        firstUpdatedRow: updatedData.find((r) => rowNumbers.includes(r.rowNumber)),
      })

      return {
        ...prev,
        rowData: updatedData,
      }
    })
  }, [])

  // Initialize filtered rows when grid props change
  useEffect(() => {
    if (gridProps?.rowData) {
      logFormattedMessage('Initializing filtered rows', { rowCount: gridProps.rowData.length })
      setFilteredRows(gridProps.rowData)
    }
  }, [gridProps?.rowData])

  useEffect(() => {
    if (gridProps?.rowData && !originalDataRef.current) {
      logFormattedMessage('Initializing originalDataRef', {
        rowCount: gridProps.rowData.length,
        sampleRow: gridProps.rowData[0],
      })
      originalDataRef.current = gridProps.rowData
    }
  }, [gridProps?.rowData])

  const handleHeaderDeleteToggle = useCallback(() => {
    if (!gridApiRef.current) {
      logFormattedMessage('handleHeaderDeleteToggle: no grid API', { headerDeleteActive })
      return
    }

    const visibleRows = []
    gridApiRef.current.forEachNodeAfterFilter((node) => {
      if (!node.data.isDuplicateRow) {
        visibleRows.push(node.data)
      }
    })

    logFormattedMessage('handleHeaderDeleteToggle: visible rows', {
      visibleRowCount: visibleRows.length,
      headerDeleteActive,
    })

    if (visibleRows.length === 0) {
      return
    }

    const shouldMark = !headerDeleteActive
    const rowNumbers = visibleRows.map((row) => row.rowNumber)

    setHeaderDeleteActive(shouldMark)
    handleToggleDelete(rowNumbers, shouldMark)
  }, [gridApiRef, headerDeleteActive, handleToggleDelete])

  const handleCellValueChanged = useCallback((params) => {
    // Skip validation for non-data columns
    if (
      [
        FEE_MGMT_COLUMNS.DELETE.columnKey,
        FEE_MGMT_COLUMNS.ERROR.columnKey,
        FEE_MGMT_COLUMNS.ROW_NUMBER.columnKey,
      ].includes(params.column.colId)
    ) {
      return
    }

    // Get all rows with the same product name
    const rowsWithSameProduct = []
    const productName = params.data[FEE_MGMT_COLUMNS.PRODUCT_NAME.columnKey]
    gridApiRef.current.forEachNode((node) => {
      if (node.data[productName] === params.data[productName]) {
        rowsWithSameProduct.push(node.data)
      }
    })

    const { data, oldValue, newValue, column } = params
    if (oldValue === newValue) {
      return
    }

    setGridProps((prev) => {
      if (!prev?.rowData) {
        return prev
      }

      const newData = prev.rowData.map((row) =>
        row.rowNumber === data.rowNumber ? { ...row, [column.colId]: newValue } : row,
      )

      // Force refresh of the cell to update styling
      if (gridApiRef.current) {
        gridApiRef.current.refreshCells({
          rowNodes: [gridApiRef.current.getRowNode(data.rowNumber.toString())],
          columns: [column.colId],
          force: true,
        })
      }

      return {
        ...prev,
        rowData: newData,
      }
    })
  }, [])

  const handleFilterChanged = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }

    const filterModel = gridApiRef.current.getFilterModel()
    const newActiveFilters = Object.entries(filterModel).map(([colId, filter]) => {
      const column = gridApiRef.current.getColumnDef(colId)
      return {
        colId,
        columnName: column.headerName || column.field,
        filter,
      }
    })

    setActiveFilters(newActiveFilters)
  }, [])

  const handleFilteredRowsChange = useCallback((rows) => {
    if (!rows) {
      return
    }
    setFilteredRows(rows)
  }, [])

  const handleRemoveFilter = useCallback(
    (colId) => {
      if (!gridApiRef.current) {
        return
      }

      gridApiRef.current.setFilterModel({
        ...gridApiRef.current.getFilterModel(),
        [colId]: null,
      })

      const column = gridApiRef.current.getColumnDef(colId)
      if (column) {
        gridApiRef.current.destroyFilter(colId)
      }
    },
    [gridApiRef],
  )

  const handleToggleExpand = useCallback((expanded) => {
    setIsSummaryExpanded(expanded)
  }, [])

  const handleValidationFilterChange = useCallback(
    (errorType, isSelected) => {
      logFormattedMessage('handleValidationFilterChange - start', {
        errorType,
        isSelected,
        hasGridApi: !!gridApiRef.current,
        currentRowCount: gridApiRef.current?.getDisplayedRowCount(),
        hasOriginalData: !!originalDataRef.current,
        originalDataCount: originalDataRef.current?.length,
      })

      if (!gridApiRef.current || !originalDataRef.current) {
        logFormattedMessage('handleValidationFilterChange - missing refs', {
          hasGridApi: !!gridApiRef.current,
          hasOriginalData: !!originalDataRef.current,
        })
        return
      }

      setGridProps((prev) => {
        if (!prev?.rowData) {
          logFormattedMessage('handleValidationFilterChange - no rowData', {
            hasRowData: false,
          })
          return prev
        }

        if (isSelected) {
          const filteredData = originalDataRef.current.filter((row) => {
            if (errorType === 'DUPLICATE_ROW') {
              return row.isDuplicateRow
            }

            return Object.values(row.validationErrors || {}).some(
              (error) => error.errorTypes && error.errorTypes.has(errorType),
            )
          })

          logFormattedMessage('handleValidationFilterChange - filtered results', {
            errorType,
            filteredCount: filteredData.length,
            originalCount: originalDataRef.current.length,
            sampleFilteredRow: filteredData[0],
          })

          return {
            ...prev,
            rowData: filteredData,
          }
        } else {
          logFormattedMessage('handleValidationFilterChange - restoring original data', {
            originalRowCount: originalDataRef.current.length,
            sampleOriginalRow: originalDataRef.current[0],
          })

          return {
            ...prev,
            rowData: originalDataRef.current,
          }
        }
      })
    },
    [gridApiRef],
  )

  return {
    gridProps,
    setGridProps,
    headerDeleteActive,
    gridApiRef,
    filteredRows,
    activeFilters,
    isSummaryExpanded,
    handleToggleDelete,
    handleHeaderDeleteToggle,
    handleCellValueChanged,
    handleFilterChanged,
    handleFilteredRowsChange,
    handleRemoveFilter,
    handleToggleExpand,
    handleValidationFilterChange,
  }
}
