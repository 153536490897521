import React, { useMemo, useCallback } from 'react'
import {
  WarningFilled,
  WarningOutlined,
  DeleteOutlined,
  DeleteFilled,
  CopyFilled,
  ExclamationCircleFilled,
  StopFilled,
  ClusterOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import { FeeValidationErrorTypes, LOCATION_TYPES, FEE_MGMT_COLUMNS, ROW_NUMBER_COL } from '../constants/Validation'
import IconHeader from '../IconHeader'
import GridSelectFilter from '../GridSelectFilter'
import FindReplaceFilter from '../FindReplaceFilter'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'

const ICON_STYLE = {
  fontSize: '18px',
  position: 'relative',
  top: '1px',
}

export const getValidationIcon = (validationState) => {
  if (!validationState) {
    return null
  }

  // Duplicate rows always take precedence
  if (validationState.isDuplicateRow) {
    return <CopyFilled style={{ ...ICON_STYLE, color: '#722ed1' }} />
  }

  // Get all unique error types across all columns
  const allErrorTypes = new Set()
  Object.values(validationState.validationErrors || {}).forEach((columnErrors) => {
    columnErrors.errorTypes.forEach((errorType) => allErrorTypes.add(errorType))
  })

  // If no errors, return null (don't show any icon)
  if (allErrorTypes.size === 0) {
    return null
  }

  const errorTypes = Array.from(allErrorTypes)

  // For multiple errors (excluding duplicates), show stop icon in red
  if (errorTypes.length > 1) {
    return <StopFilled style={{ ...ICON_STYLE, color: '#ff4d4f' }} />
  }

  // For single error types
  const errorType = errorTypes[0]
  switch (errorType) {
    case FeeValidationErrorTypes.MISSING_VALUE:
      return <ExclamationCircleFilled style={{ ...ICON_STYLE, color: '#ff4d4f' }} />
    case FeeValidationErrorTypes.INVALID_FORMAT:
      return <WarningFilled style={{ ...ICON_STYLE, color: '#faad14' }} />
    case FeeValidationErrorTypes.DUPLICATE_STATE:
    case FeeValidationErrorTypes.DUPLICATE_COUNTY:
    case FeeValidationErrorTypes.DUPLICATE_ZIP:
      return <ClusterOutlined style={{ ...ICON_STYLE, color: '#1890ff' }} />
    default:
      return <WarningFilled style={{ ...ICON_STYLE, color: '#faad14' }} />
  }
}

export const ErrorCellRenderer = React.memo(({ data }) => {
  if (!data) {
    return null
  }

  const icon = getValidationIcon(data)
  if (!icon) {
    return null
  }

  return (
    <div className="error-cell" style={{ cursor: 'default' }}>
      {icon}
    </div>
  )
})

export const DeleteCellRenderer = React.memo(({ data, context }) => {
  if (!data || !context) {
    return <span className="delete-cell" />
  }

  const handleClick = (e) => {
    e.stopPropagation()
    // If it's a duplicate row, don't allow unmarking
    if (data.isDuplicateRow) {
      return
    }
    context.onToggleDelete([data.rowNumber], !data.userMarkedForDelete)
  }

  const isDuplicateRow = data.isDuplicateRow
  const isUserMarkedForDelete = data.userMarkedForDelete

  const deleteCellStyle = {
    fontSize: '16px',
    cursor: isDuplicateRow ? 'not-allowed' : 'pointer',
  }

  return (
    <span className="delete-cell" style={{ cursor: isDuplicateRow ? 'not-allowed' : 'pointer' }}>
      {isDuplicateRow || isUserMarkedForDelete ? (
        <DeleteFilled className="anticon delete-marked" style={deleteCellStyle} onClick={handleClick} />
      ) : (
        <DeleteOutlined className="anticon" style={deleteCellStyle} onClick={handleClick} />
      )}
    </span>
  )
})

ErrorCellRenderer.propTypes = {
  data: PropTypes.object,
}

DeleteCellRenderer.propTypes = {
  data: PropTypes.object,
  context: PropTypes.shape({
    onToggleDelete: PropTypes.func.isRequired,
  }),
}

DeleteCellRenderer.defaultProps = {
  data: undefined,
  context: undefined,
}

const useGridColumns = ({ rowData, headerDeleteActive, onHeaderDeleteToggle }) => {
  // Track column existence
  const columnExistsInData = useMemo(() => {
    const exists = {}
    if (!Array.isArray(rowData)) {
      return exists
    }

    // Initialize all columns as not hidden
    Object.values(FEE_MGMT_COLUMNS).forEach((col) => {
      exists[col.columnKey] = true
    })

    logFormattedMessage('Column existence check', { exists })
    return exists
  }, [rowData])

  // Common column configuration factory
  const createBaseColumn = useCallback(
    (columnConfig) => {
      const shouldHide = !columnExistsInData[columnConfig.columnKey] // Check against columnKey instead of readable
      logFormattedMessage('Creating base column', {
        columnKey: columnConfig.columnKey,
        readable: columnConfig.readable,
        shouldHide,
        exists: columnExistsInData[columnConfig.columnKey],
      })

      const baseConfig = {
        colId: columnConfig.columnKey,
        field: columnConfig.columnKey,
        headerName: columnConfig.readable,
        editable: true,
        hide: shouldHide,
        cellClass: (params) => {
          const errorField = columnConfig.columnKey.toLowerCase()
          if (!params.data?.validationErrors?.[errorField]) {
            return ''
          }
          return params.data.validationErrors[errorField].errorTypes.size > 0 ? 'cell-has-error' : ''
        },
        context: {
          ...columnConfig.context.customGridColumnOptions,
        },
      }

      return baseConfig
    },
    [columnExistsInData],
  )

  // Factory for creating select filter columns
  const createSelectFilterColumn = useCallback(
    (columnConfig, options, doesFilterPassFn) => {
      const baseConfig = createBaseColumn(columnConfig)
      return {
        ...baseConfig,
        filter: GridSelectFilter,
        filterParams: {
          options,
          doesFilterPassFn,
        },
      }
    },
    [createBaseColumn],
  )

  // Factory for creating find/replace filter columns
  const createFindReplaceColumn = useCallback(
    (columnConfig) => {
      const baseConfig = createBaseColumn(columnConfig)
      return {
        ...baseConfig,
        filter: FindReplaceFilter,
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        },
      }
    },
    [createBaseColumn],
  )

  const createErrorColumn = useCallback(() => {
    const baseErrorColumn = createSelectFilterColumn(
      FEE_MGMT_COLUMNS.ERROR,
      [
        { value: 'errors', label: 'Has Errors' },
        { value: 'noErrors', label: 'No Errors' },
      ],
      (value, data, filterText) => {
        if (value === 'validation') {
          const errorType = filterText
          const cellErrors = data.validationErrors || {}
          return Object.values(cellErrors).some((error) => error.errorTypes && error.errorTypes.has(errorType))
        }
        if (value === 'errors') {
          return (
            data.isDuplicateRow ||
            (data.validationErrors && Object.values(data.validationErrors).some((col) => col.errorTypes?.size > 0))
          )
        }
        if (value === 'noErrors') {
          return (
            !data.isDuplicateRow &&
            (!data.validationErrors || Object.values(data.validationErrors).every((col) => !col.errorTypes?.size))
          )
        }
        return true
      },
    )

    return {
      ...baseErrorColumn,
      headerComponent: IconHeader,
      headerComponentParams: {
        icon: WarningOutlined,
        tooltip: 'Error',
        showMenu: true,
      },
      width: 48,
      cellRenderer: ErrorCellRenderer,
      pinned: 'left',
      cellClass: 'action-cell align-left',
      editable: false,
      sort: 'desc',
      sortIndex: 0,
      valueGetter: (params) => {
        const hasErrors =
          params.data.validationErrors &&
          Object.values(params.data.validationErrors).some((col) => col.errorTypes?.size > 0)
        return hasErrors ? 1 : 0
      },
    }
  }, [createSelectFilterColumn])

  // Memoize column definitions
  const columnDefs = useMemo(() => {
    logFormattedMessage('Creating column definitions', {
      hasDeleteColumn: true,
      hasErrorColumn: true,
      baseColumns: ['productName', 'vendor', 'locationType', 'states', 'counties', 'zipCodes', 'fee', 'dueDate'],
    })

    const columns = [
      // Row number column
      {
        colId: ROW_NUMBER_COL.columnKey,
        field: ROW_NUMBER_COL.columnKey,
        headerName: ROW_NUMBER_COL.readable,
        width: 48,
        pinned: 'left',
        editable: false,
        filter: false,
        sortable: true,
        cellClass: 'align-right',
      },
      // Delete column
      {
        ...createSelectFilterColumn(
          FEE_MGMT_COLUMNS.DELETE,
          [
            { value: 'marked', label: 'Marked' },
            { value: 'unmarked', label: 'Unmarked' },
          ],
          (value, data) => {
            if (value === 'marked') {
              return data.isDuplicateRow || data.userMarkedForDelete
            }
            if (value === 'unmarked') {
              return !data.isDuplicateRow && !data.userMarkedForDelete
            }
            return true
          },
        ),
        headerComponent: IconHeader,
        headerComponentParams: {
          icon: DeleteOutlined,
          tooltip: 'Delete',
          showMenu: false,
          onClick: () => {
            onHeaderDeleteToggle()
          },
        },
        cellRenderer: (params) => {
          const isMarked = params.data?.userMarkedForDelete || params.data?.isDuplicateRow
          return (
            <div className="delete-cell">
              {isMarked ? <DeleteFilled className="delete-marked" /> : <DeleteOutlined />}
            </div>
          )
        },
        cellClass: 'action-cell align-left',
        editable: false,
        width: 48,
        pinned: 'left',
        onCellClicked: (params) => {
          const context = params.context || {}
          context.onToggleDelete([params.data.rowNumber], !params.data.userMarkedForDelete)
        },
      },
      // Error column
      createErrorColumn(),
      // Data columns
      createFindReplaceColumn(FEE_MGMT_COLUMNS.PRODUCT_NAME),
      createFindReplaceColumn(FEE_MGMT_COLUMNS.VENDOR),
      {
        ...createSelectFilterColumn(
          FEE_MGMT_COLUMNS.LOCATION_TYPE,
          Object.values(LOCATION_TYPES).map((type) => ({ value: type, label: type })),
          (value, data) => (value === 'all' ? true : data.locationType === value),
        ),
        cellClass: (params) => {
          const hasError =
            params.data?.validationErrors?.[FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey]?.errorTypes?.size > 0
          return hasError ? 'cell-has-error' : ''
        },
        width: 120,
      },
      createFindReplaceColumn(FEE_MGMT_COLUMNS.STATES),
      createFindReplaceColumn(FEE_MGMT_COLUMNS.COUNTIES),
      createFindReplaceColumn(FEE_MGMT_COLUMNS.ZIP_CODES),
      createFindReplaceColumn(FEE_MGMT_COLUMNS.FEE),
      createFindReplaceColumn(FEE_MGMT_COLUMNS.DUE_DATE),
    ]

    logFormattedMessage('Column definitions created', {
      totalColumns: columns.length,
      columnFields: columns.map((c) => c.field),
    })

    return columns
  }, [onHeaderDeleteToggle, createSelectFilterColumn, createFindReplaceColumn, createErrorColumn])

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      suppressMovable: true,
      menuTabs: ['filterMenuTab'],
      filter: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        maxNumConditions: 1,
      },
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      unSortIcon: true,
      singleClickEdit: true,
    }),
    [],
  )

  return { columnDefs, defaultColDef }
}

export default useGridColumns
