import React, { useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import ViewOverride from './ViewOverride'
import useGridColumns from '../hooks/useGridColumns'
import { useGridState } from '../hooks/useGridState'
import { fetchOverrideSheets, fetchOverrideData } from '../../../store/reducers/feeManagement/feeManagementSlice'
import {
  selectOverrideSheetById,
  selectOverrideSheetsLoading,
  selectOverrideSheetsError,
  selectOverrideDataBySheetId,
  selectOverrideDataLoading,
  selectOverrideDataError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'

const mapApiDataToGridData = (apiData) => {
  if (!Array.isArray(apiData)) {
    logFormattedMessage('mapApiDataToGridData: No data to map', { apiData })
    return []
  }

  const mappedData = apiData.map((item) => {
    const mapped = {
      rowNumber: item._id,
      Delete: item.userMarkedForDelete || false,
      Error: false,
      productName: item.product_id,
      vendor: item.panelist_id,
      locationType: item.location_type,
      states: Array.isArray(item.states) ? item.states.join(', ') : '',
      counties: Array.isArray(item.counties) ? item.counties.join(', ') : '',
      zipCodes: Array.isArray(item.zips) ? item.zips.join(', ') : '',
      fee: item.override_data?.fee,
      dueDate: item.override_data?.due_date,
      validationErrors: item.validationErrors || {},
      userMarkedForDelete: item.userMarkedForDelete || false,
      isDuplicateRow: item.isDuplicateRow || false,
    }
    return mapped
  })

  logFormattedMessage('Mapped data fields', {
    sampleFields: Object.keys(mappedData[0]),
    totalFields: Object.keys(mappedData[0]).length,
  })
  return mappedData
}

const ViewOverrideContainer = () => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const override = useSelector((state) => selectOverrideSheetById(state, id))
  const overrideData = useSelector((state) => selectOverrideDataBySheetId(state, id))
  const isLoadingSheet = useSelector(selectOverrideSheetsLoading)
  const isLoadingData = useSelector(selectOverrideDataLoading)
  const sheetError = useSelector(selectOverrideSheetsError)
  const dataError = useSelector(selectOverrideDataError)

  // Add grid state management
  const {
    headerDeleteActive,
    handleToggleDelete,
    handleHeaderDeleteToggle,
    handleCellValueChanged,
    handleFilterChanged,
  } = useGridState()

  // Get column definitions using the hook
  const { columnDefs, defaultColDef } = useGridColumns({
    rowData: overrideData,
    headerDeleteActive,
    onHeaderDeleteToggle: handleHeaderDeleteToggle,
  })

  // Add default grid props
  const defaultGridProps = useMemo(() => {
    const props = {
      rowData: [],
      onToggleDelete: handleToggleDelete,
      onCellValueChanged: handleCellValueChanged,
      onFilterChanged: handleFilterChanged,
      validationSummary: null,
      columnDefs,
      defaultColDef,
      validationErrorTypes: {},
      validationConfig: {},
    }
    logFormattedMessage('Created default grid props', {
      columnCount: props.columnDefs.length,
      columns: props.columnDefs.map((c) => c.field),
    })
    return props
  }, [columnDefs, defaultColDef, handleToggleDelete, handleCellValueChanged, handleFilterChanged])

  // Create grid props
  useMemo(() => {
    logFormattedMessage('Creating grid props', {
      hasOverrideData: !!overrideData,
      isLoadingSheet,
      isLoadingData,
      defaultColumnCount: defaultGridProps.columnDefs.length,
    })

    if (!overrideData || isLoadingSheet || isLoadingData) {
      return defaultGridProps
    }

    const props = {
      ...defaultGridProps,
      rowData: mapApiDataToGridData(overrideData),
    }

    logFormattedMessage('Created grid props', {
      rowCount: props.rowData.length,
      columnCount: props.columnDefs.length,
      columns: props.columnDefs.map((c) => c.field),
      sampleRow: props.rowData[0],
    })

    return props
  }, [overrideData, isLoadingSheet, isLoadingData, defaultGridProps])

  // Transform override data for the view component
  const transformedOverride = useMemo(() => {
    if (!override) {
      return null
    }

    logFormattedMessage('Transforming override data', {
      id: override.id,
      type: override.overrideType,
      fields: override.overriddenFields,
    })

    return {
      id: override.id,
      title: override.title,
      status: override.status,
      overriddenFields: override.overriddenFields,
      overrideType: override.overrideType,
      details: {
        'Created By': override.created_by,
        'Created Date': new Date(override.created).toLocaleDateString(),
        'Last Modified': new Date(override.created).toLocaleDateString(),
        'Total Overrides': overrideData?.length || 0,
      },
    }
  }, [override, overrideData?.length])

  // Fetch override sheets if not already loaded
  useEffect(() => {
    logFormattedMessage('Loading override details', { id })
    if (!override) {
      dispatch(fetchOverrideSheets())
    }
    dispatch(fetchOverrideData(id))
  }, [dispatch, id, override])

  // Cleanup effect for unmounting
  useEffect(() => {
    return () => {
      // Cleanup any subscriptions here
      logFormattedMessage('ViewOverrideContainer unmounting', { id })
    }
  }, [id])

  const handleBack = () => {
    logFormattedMessage('Navigating back from override view', {
      id,
      type: override?.overrideType,
    })

    if (override?.overrideType) {
      history.push(`/fee-management/overrides/${override.overrideType}`)
    } else {
      history.push('/fee-management')
    }
  }

  const error = sheetError || dataError
  if (error) {
    logFormattedMessage('Error loading override', { id, error })
    return (
      <div className="view-override">
        <div className="error-state">
          Error loading override: {error}
          <Button onClick={handleBack}>Back</Button>
        </div>
      </div>
    )
  }

  return (
    <ViewOverride
      override={transformedOverride}
      overrideData={overrideData}
      isLoading={isLoadingSheet || isLoadingData}
      onBack={handleBack}
    />
  )
}

export default ViewOverrideContainer
