import { useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'

const SECTION_TITLES = {
  location: 'Location Override',
  vendor: 'Vendor Override',
  vendorLocation: 'Vendor Location Override',
}

export const useOverrideManagement = () => {
  const history = useHistory()
  const [selectedFields, setSelectedFields] = useState([])
  const [activeSection, setActiveSection] = useState(null)

  const sectionTitle = useMemo(() => {
    return activeSection ? SECTION_TITLES[activeSection] : ''
  }, [activeSection])

  const handleAddOverride = useCallback(
    (type) => {
      logFormattedMessage('Initiating add override', { type })
      if (!type) {
        logFormattedMessage('Error: No override type provided', { type })
        return
      }
      setActiveSection(type)
      history.push(`/fee-management/overrides/${type}/create`)
    },
    [history],
  )

  const handleBack = useCallback(() => {
    logFormattedMessage('Navigating back')
    setActiveSection(null)
    history.goBack()
  }, [history])

  const handleFieldsChange = useCallback((fields) => {
    logFormattedMessage('Fields changed', { fields })
    setSelectedFields(fields)
  }, [])

  return {
    activeSection,
    selectedFields,
    sectionTitle,
    handleAddOverride,
    handleBack,
    handleFieldsChange,
  }
}
