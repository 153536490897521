import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Space, Checkbox, Card, Tag, Input, Result } from 'antd'
import { Form, Field } from 'react-final-form'
import {
  ArrowLeftOutlined,
  GoldOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import FileUploadContainer from '../FileUploadContainer'
import GridManager from './GridManager'
import { REQUIRED_COLUMNS_BY_OVERRIDE_TYPE, OPTIONAL_COLUMNS } from '../constants/Validation'
import '../styles/CreateOverride.scss'
import '../styles/OverridesShared.scss'
import LoadingSpinner from './LoadingSpinner'
import CreateOverrideStepper from './CreateOverrideStepper'

const { Title, Text } = Typography

const OVERRIDE_ICONS = {
  location: EnvironmentOutlined,
  vendor: TeamOutlined,
  vendorLocation: GoldOutlined,
}

const CreateOverride = ({ overrideProps, fileHandlingProps, formProps, stepperProps, gridProps }) => {
  const { title, type: overrideType, onBack } = overrideProps
  const { onFileUpload, fileName, validationErrors, validationSummary, validationConfig } = fileHandlingProps
  const { selectedFields, onFieldsChange, onOverrideNameChange, initialValues, canProceedToDataReview } = formProps
  const { currentStep, onNext, steps } = stepperProps
  const { gridData, isLoading, onSave } = gridProps

  const Icon = OVERRIDE_ICONS[overrideType]

  const { requiredColumns, optionalColumns } = useMemo(() => {
    return {
      requiredColumns: [
        ...REQUIRED_COLUMNS_BY_OVERRIDE_TYPE.common,
        ...(REQUIRED_COLUMNS_BY_OVERRIDE_TYPE[overrideType] || []),
      ],
      optionalColumns: OPTIONAL_COLUMNS,
    }
  }, [overrideType])

  const handleFieldsChange = (checkedValues) => {
    onFieldsChange(checkedValues)
  }

  const renderConfigurationStep = () => (
    <Card className="setup-card">
      <Form
        onSubmit={onSave}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {}
          if (!values.overrideName?.trim()) {
            errors.overrideName = 'Please enter an override name'
          }
          if (!values.selectedFields?.length) {
            errors.selectedFields = 'Please select at least one product attribute to override'
          }
          return errors
        }}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-item override-name-item">
              <Space>
                <span>
                  Override Name<span style={{ color: '#ff4d4f', marginLeft: '4px' }}>*</span>
                </span>
                <Text type="secondary" className="required-indicator">
                  Required
                </Text>
              </Space>
              <Field name="overrideName">
                {({ input, meta }) => (
                  <>
                    <Input
                      {...input}
                      onChange={(e) => {
                        input.onChange(e)
                        onOverrideNameChange(e.target.value)
                      }}
                      placeholder="Enter a name for this override"
                    />
                    {meta.error && meta.touched && <div className="error-text">{meta.error}</div>}
                  </>
                )}
              </Field>
            </div>

            <div className="form-item">
              <label>Required CSV File Columns</label>
              <Space size={[0, 8]} wrap>
                {[...requiredColumns, ...values.selectedFields].map((field) => (
                  <Tag key={field} className="override-tag required-tag">
                    {field}
                  </Tag>
                ))}
              </Space>
            </div>

            <div className="form-item">
              <label>
                Override Fields<span style={{ color: '#ff4d4f', marginLeft: '4px' }}>*</span>
              </label>
              <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Field name="selectedFields">
                  {({ input, meta }) => (
                    <>
                      <Checkbox.Group
                        className="override-checkbox-group"
                        style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
                        options={optionalColumns.map((field) => ({
                          label: <span style={{ display: 'flex', alignItems: 'center' }}>{field}</span>,
                          value: field,
                        }))}
                        value={input.value}
                        onChange={(checkedValues) => {
                          input.onChange(checkedValues)
                          handleFieldsChange(checkedValues)
                        }}
                      />
                      {meta.error && meta.touched && <div className="error-text">{meta.error}</div>}
                    </>
                  )}
                </Field>
                {values.selectedFields?.length > 0 && (
                  <Space size={[0, 8]} wrap>
                    {values.selectedFields.map((field) => (
                      <Tag key={field} className="override-tag field-tag">
                        {field === 'Fee' && <DollarOutlined />}
                        {field === 'Due Date' && <CalendarOutlined />}
                        {field}
                      </Tag>
                    ))}
                  </Space>
                )}
              </Space>
            </div>

            <div className="form-item">
              <label>
                Upload Data<span style={{ color: '#ff4d4f', marginLeft: '4px' }}>*</span>
              </label>
              <Space direction="vertical" size="small">
                <FileUploadContainer onFileUpload={onFileUpload} />
                <div className="upload-help-text">
                  <Text type="secondary">
                    {selectedFields.length === 0
                      ? 'Select at least one field to override before uploading a file'
                      : fileName
                        ? `${fileName} uploaded successfully`
                        : 'Upload a CSV file containing your override data'}
                  </Text>
                </div>
              </Space>
              {validationErrors.length > 0 && (
                <div className="error-text">
                  {validationErrors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="step-actions">
              <Space>
                <Button onClick={onBack}>Cancel</Button>
                <Button
                  type="primary"
                  onClick={() => {
                    if (!canProceedToDataReview) {
                      form.submit()
                      return
                    }
                    onNext()
                  }}
                  disabled={!canProceedToDataReview}
                >
                  Next
                </Button>
              </Space>
            </div>
          </form>
        )}
      />
    </Card>
  )

  const renderDataReviewStep = () => {
    return (
      <div className="data-review">
        {isLoading ? (
          <LoadingSpinner text="Fetching override details and data..." className="overlay" />
        ) : (
          <GridManager
            initialRowData={gridData}
            validationSummary={validationSummary}
            validationConfig={validationConfig}
            onSave={onSave}
            totalErrors={validationSummary?.totalErrorCount || 0}
          />
        )}
      </div>
    )
  }

  const renderSummaryStep = () => (
    <Card className="summary-card">
      <Result
        status="success"
        title="Override Created Successfully"
        subTitle={`Your ${title.toLowerCase()} has been created.`}
        extra={[
          <Button type="primary" key="done" onClick={onBack}>
            Done
          </Button>,
        ]}
      />
    </Card>
  )

  const stepContent = {
    0: renderConfigurationStep,
    1: renderDataReviewStep,
    2: renderSummaryStep,
  }

  return (
    <div className="create-override">
      <div className="create-override-header">
        <Button type="text" icon={<ArrowLeftOutlined />} onClick={onBack} className="back-button" />
        <Space align="center" size={12}>
          {Icon && <Icon className="override-icon" />}
          <Title level={2} style={{ margin: 0 }}>
            {title}
          </Title>
        </Space>
      </div>

      <div className="create-override-content">
        <CreateOverrideStepper currentStep={currentStep} steps={steps} />
        <div className="step-content">{stepContent[currentStep]()}</div>
      </div>
    </div>
  )
}

CreateOverride.propTypes = {
  overrideProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['location', 'vendor', 'vendorLocation']).isRequired,
    onBack: PropTypes.func.isRequired,
  }).isRequired,

  fileHandlingProps: PropTypes.shape({
    onFileUpload: PropTypes.func.isRequired,
    fileName: PropTypes.string.isRequired,
    validationErrors: PropTypes.array.isRequired,
    validationSummary: PropTypes.object,
    validationConfig: PropTypes.object,
  }).isRequired,

  formProps: PropTypes.shape({
    selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFieldsChange: PropTypes.func.isRequired,
    overrideName: PropTypes.string.isRequired,
    onOverrideNameChange: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    canProceedToDataReview: PropTypes.bool.isRequired,
  }).isRequired,

  stepperProps: PropTypes.shape({
    currentStep: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
      }),
    ).isRequired,
  }).isRequired,

  gridProps: PropTypes.shape({
    gridData: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
  }).isRequired,
}

export default CreateOverride
