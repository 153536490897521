import React from 'react'
import PropTypes from 'prop-types'
import { Steps } from 'antd'

const { Step } = Steps

const CreateOverrideStepper = ({ currentStep, steps }) => {
  return (
    <Steps current={currentStep} className="override-steps">
      {steps.map((step, index) => {
        const Icon = step.icon
        return <Step key={index} title={step.title} description={step.description} icon={<Icon />} />
      })}
    </Steps>
  )
}

CreateOverrideStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
}

export default CreateOverrideStepper
