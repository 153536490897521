/* Custom console logging utility to include the file path and line number of the caller
 * Formats objects as JSON inline for easy copy/paste into cursor for debugging
 */
const logFormattedMessage = (message, data = {}) => {
  const stack = new Error().stack
  const callerLine = stack.split('\n')[2].trim() // Adjust index as needed
  const filePath = callerLine.substring(callerLine.indexOf('at ') + 3, callerLine.lastIndexOf(':'))
  const lineNumber = callerLine.substring(callerLine.lastIndexOf(':') + 1, callerLine.lastIndexOf(')'))
  console.log(`[${new Date().toISOString()}] ${message} - ${filePath}:${lineNumber}`, JSON.stringify(data, null, 2))
}

export { logFormattedMessage }
