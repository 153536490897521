import React from 'react'
import PropTypes from 'prop-types'
import { Button, Space, Card, Statistic, Row as AntRow, Col as AntCol } from 'antd'
import {
  ToolOutlined,
  CheckCircleFilled,
  CaretRightOutlined,
  CloseCircleFilled,
  DeleteFilled,
  FileTextOutlined,
} from '@ant-design/icons'
import './styles/GridControlsCard.scss'

const GridControlsCard = ({ isExpanded, onToggleExpand, fileName, rowCounts, flashFields, sections = [] }) => {
  if (!isExpanded) {
    return (
      <div className="rotated-title">
        <Button
          type="text"
          icon={<CaretRightOutlined />}
          onClick={() => onToggleExpand(true)}
          className="expand-button"
        />
        <div className="content-wrapper">
          <span>Grid Controls</span>
          <ToolOutlined />
        </div>
      </div>
    )
  }

  return (
    <Card
      className="grid-controls-card"
      title={
        <Space>
          <ToolOutlined />
          <span>Grid Controls</span>
        </Space>
      }
      extra={<Button type="text" icon={<CaretRightOutlined rotate={180} />} onClick={() => onToggleExpand(false)} />}
    >
      <AntRow gutter={24}>
        <AntCol span={12}>
          <Statistic
            className={flashFields.has('total') ? 'flash-highlight' : ''}
            title="Total Rows"
            value={rowCounts.total}
            prefix={<FileTextOutlined />}
          />
        </AntCol>
        <AntCol span={12}>
          <Statistic
            className={flashFields.has('valid') ? 'flash-highlight' : ''}
            title="Valid Rows"
            value={rowCounts.valid}
            valueStyle={{ color: '#52c41a' }}
            prefix={<CheckCircleFilled />}
          />
        </AntCol>
      </AntRow>

      <AntRow style={{ marginTop: '16px' }} gutter={24}>
        <AntCol span={12}>
          <Statistic
            className={flashFields.has('invalid') ? 'flash-highlight' : ''}
            title="Invalid Rows"
            value={rowCounts.invalid}
            valueStyle={{ color: '#ff4d4f' }}
            prefix={<CloseCircleFilled />}
          />
        </AntCol>
        <AntCol span={12}>
          <Statistic
            className={flashFields.has('pendingDeletion') ? 'flash-highlight' : ''}
            title="Pending Deletion"
            value={rowCounts.pendingDeletion}
            valueStyle={{ color: '#ff4d4f' }}
            prefix={<DeleteFilled />}
          />
        </AntCol>
      </AntRow>

      {sections.map((section, index) => (
        <React.Fragment key={index}>{section}</React.Fragment>
      ))}
    </Card>
  )
}

GridControlsCard.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  rowCounts: PropTypes.shape({
    total: PropTypes.number.isRequired,
    valid: PropTypes.number.isRequired,
    invalid: PropTypes.number.isRequired,
    pendingDeletion: PropTypes.number.isRequired,
  }).isRequired,
  flashFields: PropTypes.instanceOf(Set),
  sections: PropTypes.arrayOf(PropTypes.node),
}

export default GridControlsCard
