import React from 'react'
import PropTypes from 'prop-types'
import { FilterOutlined } from '@ant-design/icons'
import { Typography, Tag } from 'antd'
import GridControlsSection from './GridControlsSection'
import { FEE_MGMT_COLS_READABLE, LOCATION_TYPES } from './constants/Validation'
const { Text } = Typography

const renderFilterValue = (filter, columnName) => {
  if (!filter) {
    return ''
  }

  if (columnName === FEE_MGMT_COLS_READABLE.DELETE) {
    return filter.value === 'marked' ? 'Marked' : 'Unmarked'
  }

  if (columnName === FEE_MGMT_COLS_READABLE.ERROR) {
    return filter.value === 'errors' ? 'Has validation errors' : 'No validation errors'
  }

  if (columnName === FEE_MGMT_COLS_READABLE.LOCATION_TYPE) {
    const locationType = Object.values(LOCATION_TYPES).find((type) => type === filter.value)
    return locationType || filter.value
  }

  if (filter.type === 'equals') {
    return `equals "${filter.filter}"`
  } else if (filter.type === 'contains') {
    return `contains "${filter.filter}"`
  } else if (filter.type === 'lessThan') {
    return `< ${filter.filter}`
  } else if (filter.type === 'greaterThan') {
    return `> ${filter.filter}`
  } else if (filter.find !== undefined) {
    // Handle FindReplaceFilter type
    return `contains "${filter.find}"`
  }
  return JSON.stringify(filter)
}

const ActiveFiltersSection = ({ activeFilters, onRemoveFilter }) => {
  return (
    <GridControlsSection title="Active Filters" icon={FilterOutlined} className="active-filters">
      {!activeFilters || activeFilters.length === 0 ? (
        <Text type="secondary" className="no-filters">
          No filters applied
        </Text>
      ) : (
        activeFilters.map(({ colId, columnName, filter }) => (
          <Tag key={colId} closable onClose={() => onRemoveFilter(colId)} style={{ margin: '0 8px 8px 0' }}>
            <span style={{ marginRight: '4px', fontWeight: 'bold' }}>{columnName}:</span>
            {renderFilterValue(filter, columnName)}
          </Tag>
        ))
      )}
    </GridControlsSection>
  )
}

ActiveFiltersSection.propTypes = {
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      colId: PropTypes.string.isRequired,
      columnName: PropTypes.string.isRequired,
      filter: PropTypes.object.isRequired,
    }),
  ),
  onRemoveFilter: PropTypes.func.isRequired,
}

export default ActiveFiltersSection
