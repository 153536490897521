import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Card, Typography, Space, Tag } from 'antd'
import {
  RightOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import '../styles/OverrideCard.scss'

const { Text, Title } = Typography

// Custom prop comparison function
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.title === nextProps.title &&
    prevProps.status === nextProps.status &&
    JSON.stringify(prevProps.details) === JSON.stringify(nextProps.details) &&
    JSON.stringify(prevProps.overriddenFields) === JSON.stringify(nextProps.overriddenFields)
  )
}

const getFieldIcon = (field) => {
  if (field.toLowerCase() === 'fee') {
    return <DollarOutlined />
  }
  if (field.toLowerCase() === 'due date') {
    return <CalendarOutlined />
  }
  return null
}

const OverrideCard = ({ title, status, details, overriddenFields, onSelect }) => {
  return (
    <Card className="override-card">
      <div className="card-main-content">
        <div className="title-section">
          <Space size="small" align="center" wrap={false}>
            <Title level={4}>{title}</Title>
            <Tag
              icon={status === 'active' ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
              color={status === 'active' ? 'success' : 'default'}
              className="override-tag"
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
            <Tag color="blue" className="override-tag field-tag">
              {overriddenFields.map((field, index) => {
                const icon = getFieldIcon(field)
                return (
                  <React.Fragment key={field}>
                    {icon}
                    {field}
                    {index < overriddenFields.length - 1 ? ' & ' : ''}
                  </React.Fragment>
                )
              })}
            </Tag>
          </Space>
        </div>

        <div className="section-divider" />
        <div className="details-section">
          <div className="key-info">
            <div className="info-row">
              {details.State && (
                <div className="detail-item">
                  <Text type="secondary">State</Text>
                  <Text strong>{details.State}</Text>
                </div>
              )}
              {details['Fee Type'] && (
                <div className="detail-item">
                  <Text type="secondary">Fee Type</Text>
                  <Text strong>{details['Fee Type']}</Text>
                </div>
              )}
              {details.Amount && (
                <div className="detail-item">
                  <Text type="secondary">Amount</Text>
                  <Text strong>{details.Amount}</Text>
                </div>
              )}
            </div>
            <div className="info-row">
              {details['Vendor Name'] && (
                <div className="detail-item">
                  <Text type="secondary">Vendor</Text>
                  <Text strong>{details['Vendor Name']}</Text>
                </div>
              )}
              <div className="detail-item">
                <Text type="secondary">Modified</Text>
                <Text strong>{details['Last Modified']}</Text>
              </div>
            </div>
          </div>
        </div>
        <div className="card-select-button-container" onClick={onSelect}>
          <RightOutlined className="select-icon" />
        </div>
      </div>
    </Card>
  )
}

OverrideCard.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  details: PropTypes.object.isRequired,
  overriddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default memo(OverrideCard, areEqual)
