import React, { useCallback, useState, useEffect } from 'react'
import { useGridFilter } from 'ag-grid-react'
import { Switch, Input, Select } from 'antd'
import { logFormattedMessage } from '../../common/utils/consoleLogging'

const FindReplaceFilter = ({ column, model, onModelChange, api }) => {
  const [findText, setFindText] = useState(model?.find || [])
  const [replaceText, setReplaceText] = useState(model?.replace || '')
  const [showReplace, setShowReplace] = useState(false)
  const [matchCase, setMatchCase] = useState(false)
  const [matchWholeWord, setMatchWholeWord] = useState(false)
  const [uniqueValues, setUniqueValues] = useState([])
  const [mounted, setMounted] = useState(true)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  useEffect(() => {
    if (!api || !mounted) {
      return
    }

    const field = column.getColDef().field
    const shouldSplitCsvCommas = column.getColDef().context?.shouldSplitCommaValues

    const updateValues = () => {
      if (!mounted) {
        return
      }

      const valuesSet = new Set()

      api.forEachNodeAfterFilter((node) => {
        const value = node.data[field]
        if (value) {
          if (shouldSplitCsvCommas) {
            value
              .toString()
              .split(',')
              .forEach((item) => {
                const trimmedItem = item.trim()
                if (trimmedItem) {
                  valuesSet.add(trimmedItem)
                }
              })
          } else {
            valuesSet.add(value.toString())
          }
        }
      })

      if (mounted) {
        setUniqueValues([...valuesSet].sort())
      }
    }

    updateValues()
    api.addEventListener('filterChanged', updateValues)

    return () => {
      api.removeEventListener('filterChanged', updateValues)
    }
  }, [api, column, findText, mounted])

  const isFilterActive = useCallback(() => {
    return !!findText && findText.length > 0
  }, [findText])

  const doesFilterPass = useCallback(
    (params) => {
      const value = params.data[column.getColDef().field]?.toString() || ''
      if (!value || !findText || findText.length === 0) {
        return false
      }

      let searchText = findText
      let fieldValue = value

      if (!matchCase) {
        searchText = Array.isArray(searchText) ? searchText.map((text) => text.toLowerCase()) : searchText.toLowerCase()
        fieldValue = fieldValue.toLowerCase()
      }

      let regex
      if (findText.length === 0) {
        // No values selected, use partial match
        regex = new RegExp(`${searchText}`, matchCase ? 'g' : 'gi')
      } else {
        // Values are selected, use whole word match
        regex = new RegExp(`\\b(${searchText.join('|')})\\b`, matchCase ? 'g' : 'gi')
      }

      return regex.test(fieldValue)
    },
    [findText, column, matchCase],
  )

  const getModel = useCallback(() => {
    if (!findText || findText.length === 0) {
      return null
    }
    return {
      find: findText,
      replace: replaceText,
      matchCase,
      matchWholeWord,
    }
  }, [findText, replaceText, matchCase, matchWholeWord])

  useGridFilter({
    doesFilterPass,
    isFilterActive,
    getModel,
    setModel: (model) => {
      setFindText(model?.find || [])
      setReplaceText(model?.replace || '')
      setMatchCase(model?.matchCase || false)
      setMatchWholeWord(model?.matchWholeWord || false)
    },
  })

  const handleFindChange = useCallback(
    (value) => {
      logFormattedMessage('Find text changed', { value })
      setFindText(value)
      onModelChange(value?.length ? { find: value } : null)
    },
    [onModelChange],
  )

  const onReplace = () => {
    if (!findText || findText.length === 0 || !replaceText || !api) {
      return
    }
    const field = column.getColDef().field

    const rowNodes = []
    api.forEachNode((node) => {
      const value = node.data[field]
      if (!value) {
        return
      }

      let searchText = findText
      let fieldValue = value.toString()

      if (!matchCase) {
        searchText = searchText.map((text) => text.toLowerCase())
        fieldValue = fieldValue.toLowerCase()
      }

      let matches = false
      if (matchWholeWord) {
        matches = searchText.some((text) => {
          const regex = new RegExp(`\\b${text}\\b`)
          return regex.test(fieldValue)
        })
      } else {
        matches = searchText.some((text) => fieldValue.includes(text))
      }

      if (matches) {
        rowNodes.push(node)
      }
    })

    rowNodes.forEach((node) => {
      const oldValue = node.data[field]
      let newValue
      if (matchWholeWord) {
        const regex = new RegExp(`\\b(${findText.join('|')})\\b`, matchCase ? 'g' : 'gi')
        newValue = oldValue.toString().replace(regex, replaceText)
      } else {
        const regex = new RegExp(`(${findText.join('|')})`, matchCase ? 'g' : 'gi')
        newValue = oldValue.toString().replace(regex, replaceText)
      }
      const newData = { ...node.data, [field]: newValue }
      api.applyTransaction({ update: [newData] })
    })
  }

  const onReset = () => {
    setFindText([])
    setReplaceText('')
    setShowReplace(false)
    setMatchCase(false)
    setMatchWholeWord(false)
    onModelChange(null)
  }

  return (
    <div className="ag-filter-body-wrapper ag-simple-filter-body-wrapper" style={{ minWidth: 220 }}>
      <div className="ag-filter-body">
        <Select
          mode="multiple"
          showSearch
          placeholder="Find text..."
          value={findText}
          onChange={handleFindChange}
          style={{ width: '100%', marginBottom: 8 }}
          options={uniqueValues.map((value) => ({
            value,
            label: value,
            tooltip: { title: value, mouseEnterDelay: 0.2 },
            style: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }))}
        />

        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Switch size="small" checked={showReplace} onChange={setShowReplace} />
          <span>Replace</span>
        </div>

        {showReplace && (
          <div className="ag-filter-replace-section" style={{ marginTop: 8 }}>
            <Input placeholder="Replace with..." value={replaceText} onChange={(e) => setReplaceText(e.target.value)} />
          </div>
        )}
      </div>

      <div
        className="ag-filter-apply-panel"
        style={{ marginTop: 8, display: 'flex', gap: '8px', justifyContent: 'flex-end' }}
      >
        <button type="button" className="ag-button ag-standard-button" onClick={onReset}>
          Reset
        </button>
        {showReplace && (
          <button
            type="button"
            className="ag-button ag-standard-button ag-filter-apply-button"
            onClick={onReplace}
            disabled={!findText || findText.length === 0 || !replaceText}
          >
            Replace All
          </button>
        )}
      </div>
    </div>
  )
}

FindReplaceFilter.displayName = 'FindReplaceFilter'

export default FindReplaceFilter
