/* global ZENDESK_URL */
import { F_ADMINISTRATION, F_APPRAISERS, F_LOANS, F_ORDERS, F_PUBLIC, F_FEES } from '../../../session/access'

export default (showReviewSettingsMenu, useNewProductOverrides) => ({
  items: [
    {
      name: 'Dashboard',
      url: 'dashboard',
      exact: true,
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
      },
      access: F_ADMINISTRATION,
    },
    {
      title: true,
      name: 'Navigation',
      wrapper: {
        element: '',
        attributes: {},
      },
      access: F_PUBLIC,
    },
    {
      name: 'Loan Files',
      url: 'loans',
      icon: 'icon-folder',
      badge: {
        variant: 'info',
      },
      access: F_LOANS,
    },
    {
      name: 'Orders',
      url: 'orders',
      icon: 'icon-list',
      badge: {
        variant: 'info',
      },
      access: F_ORDERS,
    },
    {
      name: 'Appraisal Panel',
      url: 'appraisers',
      icon: 'icon-people',
      badge: {
        variant: 'info',
      },
      children: [
        {
          name: 'Appraiser Management',
          url: 'appraisers',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Exclusionary List',
          url: 'exclusionaryList',
          badge: {
            variant: 'info',
          },
        },
      ],
      access: F_APPRAISERS,
    },
    {
      name: 'Products',
      url: 'fee',
      icon: 'fa fa-clipboard',
      access: F_FEES,
    },
    useNewProductOverrides && {
      name: 'Product Fee Management',
      url: 'fee-management',
      icon: 'fa fa-table',
      access: F_FEES, // TOOD BANDREWS UPDATE THIS
    },
    {
      name: 'Branches',
      url: 'branches',
      icon: 'fa fa-building',
      badge: {
        variant: 'info',
      },
      access: F_ADMINISTRATION,
    },
    {
      name: 'Users',
      url: 'users',
      icon: 'icon-user',
      badge: {
        variant: 'info',
      },
      children: [
        {
          name: 'User Management',
          url: 'users',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'User Teams',
          url: 'userTeams',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'User Roles and Permissions',
          url: 'userRoles',
          badge: {
            variant: 'info',
          },
        },
      ],
      access: F_ADMINISTRATION,
    },
    {
      name: 'Accounting',
      url: 'accounting',
      icon: 'fa fa-money-bill',
      badge: {
        variant: 'info',
      },
      access: F_ADMINISTRATION,
    },
    {
      name: 'Settings',
      url: 'admin_settings',
      icon: 'icon-wrench',
      badge: {
        variant: 'info',
      },
      access: F_ADMINISTRATION,
    },
    showReviewSettingsMenu && {
      name: 'Review Settings',
      url: 'reviewSettings',
      icon: 'file-search',
      badge: {
        variant: 'info',
      },
      access: F_ADMINISTRATION,
      publicRoute: true,
      children: [
        {
          name: 'Rule Sets',
          url: 'reviewRuleSets',
          badge: {
            variant: 'info',
          },
          publicRoute: true,
        },
      ],
    },
    // eslint-disable-next-line no-undef
    DEPLOYMENT_ENVIRONMENT === 'production' && {
      name: 'Help Center',
      url: ZENDESK_URL,
      icon: 'fas fa-life-ring',
      badge: {
        variant: 'info',
      },
      target: '_blank',
      publicRoute: true,
    },
  ],
})
