import React, { memo, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import { GoldOutlined, TeamOutlined, EnvironmentOutlined } from '@ant-design/icons'
import OverrideSummary from './OverrideSummary'
import OverrideTypeCard from './OverrideTypeCard'
import LoadingSpinner from './LoadingSpinner'
import CreateOverrideManager from './CreateOverrideManager'
import { fetchOverrideSheets } from '../../../store/reducers/feeManagement/feeManagementSlice'
import {
  selectOverrideSheetsGroupedByType,
  selectOverrideSheetsLoading,
  selectOverrideSheetsError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'
import { useOverrideManagement } from '../hooks/useOverrideManagement'
import { FeeValidationErrorTypes } from '../constants/Validation'
import '../styles/ProductFeeMgmt.scss'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'
const OverrideManager = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  // Get data from Redux
  const overrideSheets = useSelector(selectOverrideSheetsGroupedByType)
  const isLoading = useSelector(selectOverrideSheetsLoading)
  const error = useSelector(selectOverrideSheetsError)

  // State management from useOverrideManagement hook
  // eslint-disable-next-line no-unused-vars
  const { activeSection, selectedFields, view, sectionTitle, handleAddOverride, handleBack, handleFieldsChange } =
    useOverrideManagement()

  // Fetch override sheets when component mounts
  useEffect(() => {
    dispatch(fetchOverrideSheets())
  }, [dispatch])

  // eslint-disable-next-line no-unused-vars
  const handleSelectOverride = (override) => {
    history.push(`/fee-management/override/${override.id}`)
  }

  const handleSave = async () => {
    try {
      handleBack()
    } catch (error) {
      console.error('Error saving override:', error)
    }
  }

  const handleSelectOverrideType = useCallback(
    (type) => {
      logFormattedMessage('Navigating to override type view', { type })
      history.push(`/fee-management/overrides/${type}`)
    },
    [history],
  )

  if (error) {
    return (
      <Container fluid>
        <div>Error loading overrides: {error}</div>
      </Container>
    )
  }

  return (
    <Container fluid className="fee-management-container">
      {view === 'create' ? (
        <CreateOverrideManager
          title={sectionTitle}
          overrideType={activeSection}
          onBack={handleBack}
          selectedFields={selectedFields}
          onFieldsChange={handleFieldsChange}
          onSave={handleSave}
          validationErrorTypes={FeeValidationErrorTypes}
        />
      ) : (
        <>
          {isLoading ? (
            <OverrideSummary
              overrides={{
                location: [],
                vendor: [],
                vendorLocation: [],
              }}
              loading={true}
            />
          ) : (
            <OverrideSummary overrides={overrideSheets} />
          )}
          <div className="override-type-cards">
            {isLoading ? (
              <LoadingSpinner text="Fetching override sheets..." />
            ) : (
              <>
                <OverrideTypeCard
                  title="Vendor Location Overrides"
                  description="Manage fee and due date overrides for specific vendors at specific locations"
                  icon={<GoldOutlined />}
                  stats={{
                    active: overrideSheets.vendorLocation.filter((o) => o.status === 'active').length,
                    total: overrideSheets.vendorLocation.length,
                  }}
                  onSelect={() => handleSelectOverrideType('vendorLocation')}
                />
                <OverrideTypeCard
                  title="Vendor Overrides"
                  description="Manage fee and due date overrides for specific vendors"
                  icon={<TeamOutlined />}
                  stats={{
                    active: overrideSheets.vendor.filter((o) => o.status === 'active').length,
                    total: overrideSheets.vendor.length,
                  }}
                  onSelect={() => handleSelectOverrideType('vendor')}
                />
                <OverrideTypeCard
                  title="Location Overrides"
                  description="Manage fee and due date overrides for specific locations"
                  icon={<EnvironmentOutlined />}
                  stats={{
                    active: overrideSheets.location.filter((o) => o.status === 'active').length,
                    total: overrideSheets.location.length,
                  }}
                  onSelect={() => handleSelectOverrideType('location')}
                />
              </>
            )}
          </div>
        </>
      )}
    </Container>
  )
}

export default memo(OverrideManager)
