import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Space, Divider } from 'antd'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'

const GridControlsSection = ({ title, icon: Icon, children, defaultExpanded = true, className = '' }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)

  return (
    <>
      <Divider>
        <Space style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
          <Icon />
          <span>{title}</span>
          {isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
        </Space>
      </Divider>
      {isExpanded && <div className={className}>{children}</div>}
    </>
  )
}

GridControlsSection.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string,
}

export default GridControlsSection
