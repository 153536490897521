import React from 'react'
import PropTypes from 'prop-types'
import FileUpload from './FileUpload'
import { useCSVProcessor } from './hooks/useCSVProcessor'
import { logFormattedMessage } from '../../common/utils/consoleLogging'

const FileUploadContainer = ({ onFileUpload }) => {
  const { isProcessing, progress, timeRemaining, processCSV, reset, formatTimeRemaining } = useCSVProcessor()

  const handleFileUpload = async (file) => {
    logFormattedMessage('FileUploadContainer handleFileUpload called', {
      fileName: file?.name,
    })

    try {
      const startTime = Date.now()
      const result = await processCSV(file)
      const totalProcessingTime = (Date.now() - startTime) / 1000

      onFileUpload(result.rows, {
        fileName: result.fileName,
        totalRows: result.totalRows,
        headers: result.headers,
        processingTime: Number(totalProcessingTime.toFixed(1)),
      })
    } catch (error) {
      console.error('Error processing CSV:', error)
      reset()
    }
  }

  const handleClear = () => {
    reset()
  }

  return (
    <FileUpload
      onFileUpload={handleFileUpload}
      onClear={handleClear}
      isProcessing={isProcessing}
      progress={progress}
      timeRemaining={timeRemaining}
      formatTimeRemaining={formatTimeRemaining}
    />
  )
}

FileUploadContainer.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
}

export default FileUploadContainer
