import { useCallback } from 'react'
import { useValidation } from './useValidation'

export const useFileUpload = ({ activeSection, selectedFields, onDataProcessed, setFileHeaders }) => {
  const { validateHeaders, validateRows } = useValidation()

  const handleFileUpload = useCallback(
    (rows, metadata) => {
      const headerValidation = validateHeaders(metadata.headers, activeSection, selectedFields)

      if (!headerValidation.isValid) {
        return { headerValidation }
      }

      const headerSet = headerValidation.headers
      setFileHeaders(headerSet)

      const filteredRows = rows.map((row) => {
        const filteredRow = {}
        headerSet.forEach((header) => {
          filteredRow[header] = row[header]
        })

        return {
          ...filteredRow,
          rowNumber: row.rowNumber,
          validationErrors: row.validationErrors,
          userMarkedForDelete: row.userMarkedForDelete,
        }
      })

      const { rows: validatedRows, summary: validationSummary } = validateRows(filteredRows, {
        overrideType: activeSection,
        headers: headerSet,
      })

      onDataProcessed(validatedRows, validationSummary)

      return { headerValidation }
    },
    [activeSection, selectedFields, onDataProcessed, setFileHeaders, validateHeaders, validateRows],
  )

  return { handleFileUpload }
}
