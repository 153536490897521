import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography, Space } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import '../styles/OverrideTypeCard.scss'

const { Text, Title } = Typography

const OverrideTypeCard = ({ title, description, icon, stats, onSelect }) => {
  const handleClick = (e) => {
    e.stopPropagation()
    onSelect()
  }

  return (
    <Card className="override-type-card" onClick={handleClick}>
      <div className="card-main-content">
        <div className="title-section">
          <Space size="small" align="center">
            <div className="icon-wrapper">{icon}</div>
            <div className="text-content">
              <Title level={4}>{title}</Title>
              <Text type="secondary">{description}</Text>
            </div>
          </Space>
        </div>

        <div className="section-divider" />

        <div className="stats-section">
          <div className="stat-item">
            <Text type="secondary">Active</Text>
            <Text strong>{stats.active}</Text>
          </div>
          <div className="stat-item">
            <Text type="secondary">Total</Text>
            <Text strong>{stats.total}</Text>
          </div>
        </div>

        <div className="card-select-button-container">
          <RightOutlined className="select-icon" />
        </div>
      </div>
    </Card>
  )
}

OverrideTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  stats: PropTypes.shape({
    active: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default OverrideTypeCard
