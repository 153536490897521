// Base selector for the fee management slice
export const selectFeeManagementState = (state) => state?.feeManagement

// Selectors for override sheets
export const selectOverrideSheetsState = (state) => selectFeeManagementState(state)?.overrideSheets

export const selectAllOverrideSheets = (state) => selectOverrideSheetsState(state)?.items || []

export const selectOverrideSheetsLoading = (state) => selectOverrideSheetsState(state)?.isLoading || false

export const selectOverrideSheetsError = (state) => selectOverrideSheetsState(state)?.error

// Selectors for filtered override sheets by type
export const selectOverrideSheetsByType = (state, type) => {
  const sheets = selectAllOverrideSheets(state)
  return sheets.filter((sheet) => sheet.overrideType === type)
}

// Selector for a specific override sheet by ID
export const selectOverrideSheetById = (state, id) => {
  const sheets = selectAllOverrideSheets(state)
  return sheets.find((sheet) => sheet.id === id)
}

// Selector for active override sheets
export const selectActiveOverrideSheets = (state) => {
  const sheets = selectAllOverrideSheets(state)
  return sheets.filter((sheet) => sheet.status === 'active')
}

// Selector for override sheets grouped by type
export const selectOverrideSheetsGroupedByType = (state) => {
  const sheets = selectAllOverrideSheets(state)

  const grouped = {
    location: sheets.filter((sheet) => sheet.overrideType === 'location'),
    vendor: sheets.filter((sheet) => sheet.overrideType === 'vendor'),
    vendorLocation: sheets.filter((sheet) => sheet.overrideType === 'vendorLocation'),
  }

  return grouped
}

// Selector for override sheet statistics
export const selectOverrideSheetStats = (state) => {
  const sheets = selectAllOverrideSheets(state)
  return {
    total: sheets.length,
    active: sheets.filter((sheet) => sheet.status === 'active').length,
    inactive: sheets.filter((sheet) => sheet.status === 'inactive').length,
    byType: {
      location: sheets.filter((sheet) => sheet.overrideType === 'location').length,
      vendor: sheets.filter((sheet) => sheet.overrideType === 'vendor').length,
      vendorLocation: sheets.filter((sheet) => sheet.overrideType === 'vendorLocation').length,
    },
  }
}

// Selectors for override data
export const selectOverrideDataState = (state) => selectFeeManagementState(state)?.overrideData

export const selectOverrideDataLoading = (state) => state.feeManagement.overrideData.isLoading || false

export const selectOverrideDataError = (state) => state.feeManagement.overrideData.error

export const selectOverrideDataBySheetId = (state, sheetId) => {
  return state.feeManagement.overrideData.data
}
