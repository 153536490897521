import ProductFeeMgmtLayout from './ProductFeeMgmtLayout'

const routes = [
  {
    path: '/',
    name: 'fee-management',
    component: ProductFeeMgmtLayout,
  },
]

export default routes
