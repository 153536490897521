import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'

// Constants for mapping entity types to override types
const OVERRIDE_TYPE_MAPPING = {
  LOCATION: ['product', 'location'],
  VENDOR: ['product', 'panelist'],
  VENDOR_LOCATION: ['product', 'location', 'panelist'],
}

export const initialState = {
  overrideSheets: {
    items: [],
    isLoading: false,
    error: null,
  },
  overrideData: {
    data: null,
    isLoading: false,
    error: null,
  },
}

// Helper function to determine override type from entity_types
const getOverrideType = (entityTypes) => {
  const sortedTypes = [...entityTypes].sort()
  const typeString = sortedTypes.join(',')

  if (typeString === OVERRIDE_TYPE_MAPPING.VENDOR_LOCATION.sort().join(',')) {
    return 'vendorLocation'
  } else if (typeString === OVERRIDE_TYPE_MAPPING.VENDOR.sort().join(',')) {
    return 'vendor'
  } else if (typeString === OVERRIDE_TYPE_MAPPING.LOCATION.sort().join(',')) {
    return 'location'
  }
  return null
}

// Helper function to format override details
const formatOverrideDetails = (sheet) => {
  const details = {
    'Last Modified': new Date(sheet.created).toLocaleDateString(),
  }

  // Add type-specific details
  const overrideType = getOverrideType(sheet.entity_types)
  if (overrideType === 'vendorLocation') {
    details.State = 'TBD' // Will come from data
    details['Vendor Name'] = 'TBD' // Will come from data
    details['Fee Type'] = sheet.data_types.includes('fee') ? 'Override' : 'Default'
  } else if (overrideType === 'vendor') {
    details['Vendor Name'] = 'TBD' // Will come from data
    details['Fee Type'] = sheet.data_types.includes('fee') ? 'Override' : 'Default'
  } else if (overrideType === 'location') {
    details.State = 'TBD' // Will come from data
    details['Fee Type'] = sheet.data_types.includes('fee') ? 'Override' : 'Default'
  }

  return details
}

export const fetchOverrideSheets = createAsyncThunk(
  'feeManagement/fetchOverrideSheets',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API('overrides/sheets/', API_VERSION.TWO).request(REQUEST_METHOD.GET)

      // Transform the response to include derived override type
      const transformedSheets = response.sheets.map((sheet) => {
        const overrideType = getOverrideType(sheet.entity_types)
        const transformed = {
          id: sheet.id,
          title: sheet.description,
          status: sheet.active ? 'active' : 'inactive',
          overrideType,
          overriddenFields: sheet.data_types.map((type) => type.charAt(0).toUpperCase() + type.slice(1)),
          details: formatOverrideDetails(sheet),
          // Keep original data for reference
          created: sheet.created,
          created_by: sheet.created_by,
          entity_types: sheet.entity_types,
          data_types: sheet.data_types,
          lender_id: sheet.lender_id,
        }
        return transformed
      })

      return transformedSheets
    } catch (err) {
      console.error('Error fetching override sheets:', err)
      return rejectWithValue(err.errors?._error || 'Failed to fetch override sheets')
    }
  },
)

export const fetchOverrideData = (sheetId) => async (dispatch) => {
  try {
    dispatch(setOverrideDataLoading(true))
    const api = API(`overrides/sheets/${sheetId}/overrides.json`, API_VERSION.TWO)
    const response = await api.request(REQUEST_METHOD.GET, {}, {}, true)
    dispatch(setOverrideData(response))
  } catch (error) {
    dispatch(setOverrideDataError(error.message))
  } finally {
    dispatch(setOverrideDataLoading(false))
  }
}

const feeManagementSlice = createSlice({
  name: 'feeManagement',
  initialState,
  reducers: {
    resetFeeManagement: () => initialState,
    setOverrideData: (state, action) => {
      state.overrideData.data = action.payload
    },
    setOverrideDataLoading: (state, action) => {
      state.overrideData.isLoading = action.payload
    },
    setOverrideDataError: (state, action) => {
      state.overrideData.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverrideSheets.pending, (state) => {
        state.overrideSheets.isLoading = true
        state.overrideSheets.error = null
      })
      .addCase(fetchOverrideSheets.fulfilled, (state, action) => {
        state.overrideSheets.items = action.payload
        state.overrideSheets.isLoading = false
        state.overrideSheets.error = null
      })
      .addCase(fetchOverrideSheets.rejected, (state, action) => {
        state.overrideSheets.isLoading = false
        state.overrideSheets.error = action.payload
      })
  },
})

export const { resetFeeManagement, setOverrideData, setOverrideDataLoading, setOverrideDataError } =
  feeManagementSlice.actions

export default feeManagementSlice.reducer
