import { useMemo, useCallback } from 'react'
import {
  ZIP_REGEX,
  STATE_REGEX,
  COUNTY_REGEX,
  FEE_MGMT_COLUMNS,
  FeeValidationErrorTypes,
  LOCATION_TYPES,
  FEE_MGMT_COLS_READABLE,
  FEE_MGMT_COLS_BY_READABLE,
  REQUIRED_COLUMNS_BY_OVERRIDE_TYPE,
  OPTIONAL_COLUMNS,
} from '../constants/Validation'

// Helper functions for multi-value fields
const parseMultiValue = (value) => {
  if (!value) {
    return new Set()
  }
  // Sort values when creating the set
  return new Set(
    [...value.split(',')]
      .map((v) => v.trim())
      .filter(Boolean)
      .sort(),
  )
}

const stringifyMultiValue = (valueSet) => {
  if (!valueSet || valueSet.size === 0) {
    return ''
  }
  return Array.from(valueSet).join(', ')
}

// Helper to create a new column validation state
const createColumnValidation = () => ({
  errorTypes: new Set(),
  details: {},
})

// Validation helper functions
const createValidationSummary = () => {
  const errorCounts = {}
  const errorRows = {}

  Object.keys(FeeValidationErrorTypes).forEach((key) => {
    const type = FeeValidationErrorTypes[key]
    errorCounts[type] = 0
    errorRows[type] = new Set()
  })

  return {
    errorCounts: errorCounts,
    errorRows: errorRows,
    totalErrorCount: 0,
    hasErrors: false,
  }
}

// Helper to add an error to a row's validation state
const addError = (validationState, columnName, errorType, details = null) => {
  // Initialize validationState if it doesn't exist
  if (!validationState) {
    validationState = {
      isDuplicateRow: false,
      userMarkedForDelete: false,
      validationErrors: {},
    }
  }
  // Initialize validationErrors if it doesn't exist
  if (!validationState.validationErrors) {
    validationState.validationErrors = {}
  }

  const columnKey = FEE_MGMT_COLUMNS[FEE_MGMT_COLS_BY_READABLE[columnName]].columnKey
  if (!validationState.validationErrors[columnKey]) {
    validationState.validationErrors[columnKey] = createColumnValidation()
  }
  validationState.validationErrors[columnKey].errorTypes.add(errorType)
  if (details) {
    if (!validationState.validationErrors[columnKey].details[errorType]) {
      validationState.validationErrors[columnKey].details[errorType] = { values: [] }
    }
    validationState.validationErrors[columnKey].details[errorType].values.push(details)
  }
}

const createRowKey = (row, availableColumns) => {
  // Create a unique key (a set of values) for each row based on the available columns
  const { LOCATION, FEE, DUE_DATE, VENDOR } = availableColumns
  const values = new Set([row[FEE_MGMT_COLS_READABLE.PRODUCT_NAME] || ''])

  if (VENDOR) {
    values.add(row[FEE_MGMT_COLS_READABLE.VENDOR] || '')
  }
  if (LOCATION) {
    values.add(stringifyMultiValue(row.states))
    values.add(stringifyMultiValue(row.counties))
    values.add(stringifyMultiValue(row.zipCodes))
  }
  if (FEE) {
    values.add(row[FEE_MGMT_COLS_READABLE.FEE] || '')
  }
  if (DUE_DATE) {
    values.add(row[FEE_MGMT_COLS_READABLE.DUE_DATE] || '')
  }

  return values
}

const createCacheKey = (row, rowKey) => `${row['Product Name'] || ''}:${rowKey}`

const validateMissingValues = (row, validationState, summary, availableColumns) => {
  const { LOCATION, VENDOR, FEE, DUE_DATE } = availableColumns

  const addMissingValueError = (columnName) => {
    addError(validationState, columnName, FeeValidationErrorTypes.MISSING_VALUE)
    summary.errorRows[FeeValidationErrorTypes.MISSING_VALUE].add(row.rowNumber)
  }

  if (LOCATION) {
    const rowLocationType = row[FEE_MGMT_COLS_READABLE.LOCATION_TYPE]?.trim()
    switch (rowLocationType) {
      case '':
        addMissingValueError(FEE_MGMT_COLS_READABLE.LOCATION_TYPE)
        break
      case LOCATION_TYPES.STATE:
        if (row.states.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_READABLE.STATES)
        }
        if (row.counties.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_READABLE.COUNTIES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            'County not allowed for Multi-State Location Type',
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        if (row.zipCodes.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_READABLE.ZIP_CODES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            'Zip Code not allowed for Multi-State Location Type',
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        break
      case LOCATION_TYPES.COUNTY:
        if (row.states.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_READABLE.STATES)
        }
        if (row.zipCodes.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_READABLE.ZIP_CODES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            'Zip Code not allowed for County Location Type',
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        if (row.counties.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_READABLE.COUNTIES)
        }
        break
      case LOCATION_TYPES.ZIP:
        if (row.states.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_READABLE.STATES)
        }
        if (row.counties.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_READABLE.COUNTIES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            'County not allowed for Zip Code Location Type',
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        if (row.zipCodes.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_READABLE.ZIP_CODES)
        }
        break
      default:
        break
    }
  }

  if (VENDOR && !row[FEE_MGMT_COLS_READABLE.VENDOR]?.trim()) {
    addMissingValueError(FEE_MGMT_COLS_READABLE.VENDOR)
  }
  if (FEE && !row[FEE_MGMT_COLS_READABLE.FEE]?.trim()) {
    addMissingValueError(FEE_MGMT_COLS_READABLE.FEE)
  }
  if (DUE_DATE && !row[FEE_MGMT_COLS_READABLE.DUE_DATE]?.trim()) {
    addMissingValueError(FEE_MGMT_COLS_READABLE.DUE_DATE)
  }
  if (!row[FEE_MGMT_COLS_READABLE.PRODUCT_NAME]?.trim()) {
    addMissingValueError(FEE_MGMT_COLS_READABLE.PRODUCT_NAME)
  }
}

const validateFormats = (row, validationState, summary) => {
  // Validate Fee is an integer
  const feeNumber = Number(row[FEE_MGMT_COLS_READABLE.FEE])
  if (!Number.isInteger(feeNumber)) {
    addError(
      validationState,
      FEE_MGMT_COLS_READABLE.FEE,
      FeeValidationErrorTypes.INVALID_FORMAT,
      'Must be a whole number',
    )
    summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
  }

  // Validate Due Date is a whole number
  const dueDateNumber = Number(row[FEE_MGMT_COLS_READABLE.DUE_DATE])
  if (!Number.isInteger(dueDateNumber)) {
    addError(
      validationState,
      FEE_MGMT_COLS_READABLE.DUE_DATE,
      FeeValidationErrorTypes.INVALID_FORMAT,
      'Must be a whole number',
    )
    summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
  }

  if (!row[FEE_MGMT_COLS_READABLE.LOCATION_TYPE]) {
    return
  }

  if (!Object.values(LOCATION_TYPES).includes(row[FEE_MGMT_COLS_READABLE.LOCATION_TYPE])) {
    addError(
      validationState,
      FEE_MGMT_COLS_READABLE.LOCATION_TYPE,
      FeeValidationErrorTypes.INVALID_FORMAT,
      'Must be a valid location type',
    )
    summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
  }

  // Validate states
  if (row.states.size > 0) {
    const invalidStates = Array.from(row.states).filter((state) => !STATE_REGEX.test(state))
    if (invalidStates.length > 0) {
      addError(
        validationState,
        FEE_MGMT_COLS_READABLE.STATES,
        FeeValidationErrorTypes.INVALID_FORMAT,
        invalidStates.join(', '),
      )
      summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
    }
  }

  // Validate counties
  if (row.counties.size > 0) {
    const invalidCounties = Array.from(row.counties).filter((county) => !COUNTY_REGEX.test(county))
    if (invalidCounties.length > 0) {
      addError(
        validationState,
        FEE_MGMT_COLS_READABLE.COUNTIES,
        FeeValidationErrorTypes.INVALID_FORMAT,
        invalidCounties.join(', '),
      )
      summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
    }
  }

  // Validate ZIP codes
  if (row.zipCodes.size > 0) {
    const invalidZips = Array.from(row.zipCodes).filter((zip) => !ZIP_REGEX.test(zip))
    if (invalidZips.length > 0) {
      addError(
        validationState,
        FEE_MGMT_COLS_READABLE.ZIP_CODES,
        FeeValidationErrorTypes.INVALID_FORMAT,
        invalidZips.join(', '),
      )
      summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
    }
  }
}

const setsAreEqual = (set1, set2) => {
  return set1.size === set2.size && [...set1].every((value) => set2.has(value))
}

const markDuplicateRows = (productGroups, markedDuplicateRows) => {
  productGroups.forEach((group) => {
    if (group.length > 1) {
      group.sort((a, b) => a.rowNumber - b.rowNumber)
      const seenRowKeys = new Map()

      group.forEach(({ rowNumber, rowKey }) => {
        let seen = false
        for (const [existingKey] of seenRowKeys) {
          if (setsAreEqual(rowKey, existingKey)) {
            seen = true
            break
          }
        }

        if (seen) {
          markedDuplicateRows.add(rowNumber)
        } else {
          seenRowKeys.set(rowKey, rowNumber) // Store the *set* as the key
        }
      })
    }
  })
}

const trackLocation = (
  locationTypeCondition,
  locationValues,
  regex,
  keyToRows,
  keyGenerator,
  groupKey,
  row,
  validationState,
  locationType,
) => {
  if (locationTypeCondition) {
    locationValues.forEach((value) => {
      if (regex.test(value)) {
        const key = keyGenerator(groupKey, value)
        if (!keyToRows.has(key)) {
          keyToRows.set(key, new Set())
        }
        keyToRows.get(key).add({
          rowNumber: row.rowNumber,
          validationState,
          locationType,
        })
      }
    })
  }
}

const trackLocations = (
  { validationState, row, states, counties, zipCodes, vendor, productName },
  { stateKeyToRows, countyKeyToRows, zipKeyToRows },
) => {
  if (!validationState.isDuplicateRow && !validationState.userMarkedForDelete) {
    const locationType = row[FEE_MGMT_COLS_READABLE.LOCATION_TYPE]?.toUpperCase()
    const groupKey = vendor ? `${productName}:${vendor}` : productName

    // Track states only for MULTI-STATE location type
    trackLocation(
      states.size > 0 && locationType === LOCATION_TYPES.STATE,
      states,
      STATE_REGEX,
      stateKeyToRows,
      (groupKey, state) => `${groupKey}:${state}`,
      groupKey,
      row,
      validationState,
      locationType,
    )

    // Track counties only for COUNTY location type
    trackLocation(
      counties.size > 0 && states.size > 0 && locationType === LOCATION_TYPES.COUNTY,
      counties,
      COUNTY_REGEX,
      countyKeyToRows,
      (groupKey, county) => {
        const state = Array.from(states)[0] // Assuming only one state for county
        return `${groupKey}:${state}:${county}`
      },
      groupKey,
      row,
      validationState,
      locationType,
    )

    // Track ZIP codes only for ZIP location type
    trackLocation(
      zipCodes.size > 0 && locationType === LOCATION_TYPES.ZIP,
      zipCodes,
      ZIP_REGEX,
      zipKeyToRows,
      (groupKey, zip) => `${groupKey}:${zip}`,
      groupKey,
      row,
      validationState,
      locationType,
    )
  }
}

const trackVendors = (validationState, vendor, productName, vendorKeyToRows, availableColumns) => {
  if (availableColumns.VENDOR && !availableColumns.LOCATION && !validationState.isDuplicateRow) {
    const vendorKey = `${productName}:${vendor}`
    if (!vendorKeyToRows.has(vendorKey)) {
      vendorKeyToRows.set(vendorKey, new Set())
    }
  }
}

const checkDuplicateLocation = (
  keyToRows,
  locationType,
  readableColumn,
  errorType,
  keySplitIndices,
  formatValue,
  summary,
) => {
  keyToRows.forEach((rowSet, key) => {
    const rowLocations = Array.from(rowSet).filter((row) => row.locationType === locationType)
    if (rowLocations.length > 1) {
      const keyParts = key.split(':')
      const extractedValues = keySplitIndices.map((index) => keyParts[index])
      const formattedValue = formatValue ? formatValue(...extractedValues) : extractedValues.join(' - ')

      rowLocations.forEach(({ rowNumber, validationState }) => {
        addError(validationState, readableColumn, errorType, formattedValue)
        summary.errorRows[errorType].add(rowNumber)
      })
    }
  })
}

const checkDuplicateLocations = (stateKeyToRows, countyKeyToRows, zipKeyToRows, summary) => {
  checkDuplicateLocation(
    stateKeyToRows,
    LOCATION_TYPES.STATE,
    FEE_MGMT_COLS_READABLE.STATES,
    FeeValidationErrorTypes.DUPLICATE_STATE,
    [2],
    null,
    summary,
  )

  checkDuplicateLocation(
    countyKeyToRows,
    LOCATION_TYPES.COUNTY,
    FEE_MGMT_COLS_READABLE.COUNTIES,
    FeeValidationErrorTypes.DUPLICATE_COUNTY,
    [-2, -1],
    (state, county) => `${state} - ${county}`,
    summary,
  )

  checkDuplicateLocation(
    zipKeyToRows,
    LOCATION_TYPES.ZIP,
    FEE_MGMT_COLS_READABLE.ZIP_CODES,
    FeeValidationErrorTypes.DUPLICATE_ZIP,
    [2],
    null,
    summary,
  )
}

const checkDuplicateVendor = (vendorKeyToRows, summary) => {
  vendorKeyToRows.forEach((rowSet, vendorKey) => {
    if (rowSet.size > 1) {
      const [, vendor] = vendorKey.split(':')
      rowSet.forEach(({ rowNumber, validationState }) => {
        addError(validationState, FEE_MGMT_COLS_READABLE.VENDOR, FeeValidationErrorTypes.DUPLICATE_VENDOR, vendor)
        summary.errorRows[FeeValidationErrorTypes.DUPLICATE_VENDOR].add(rowNumber)
      })
    }
  })
}

const createProductGroups = (rows, availableColumns, productGroups, rowLocationMap) => {
  rows.forEach((row) => {
    const productName = row[FEE_MGMT_COLS_READABLE.PRODUCT_NAME] || ''
    const states = availableColumns.LOCATION ? parseMultiValue(row[FEE_MGMT_COLS_READABLE.STATES]) : new Set()
    const counties = availableColumns.LOCATION ? parseMultiValue(row[FEE_MGMT_COLS_READABLE.COUNTIES]) : new Set()
    const zipCodes = availableColumns.LOCATION ? parseMultiValue(row[FEE_MGMT_COLS_READABLE.ZIP_CODES]) : new Set()
    rowLocationMap.set(row.rowNumber, { states: states, counties: counties, zipCodes: zipCodes })
    const rowKey = createRowKey({ ...row, states, counties, zipCodes }, availableColumns)

    if (!productGroups.has(productName)) {
      productGroups.set(productName, [])
    }
    productGroups.get(productName).push({ rowNumber: row.rowNumber, rowKey, row })
  })
}

const ensureHeaderSet = (headers) => {
  if (headers instanceof Set) {
    return headers
  }
  return new Set(headers)
}

const getRequiredColumns = (overrideType, selectedFields) => {
  // get the columns from selected fields that are in optional columns
  const optionalColumns = selectedFields.filter((field) => OPTIONAL_COLUMNS.includes(field))
  return new Set([
    ...REQUIRED_COLUMNS_BY_OVERRIDE_TYPE.common,
    ...REQUIRED_COLUMNS_BY_OVERRIDE_TYPE[overrideType],
    ...optionalColumns,
  ])
}

export const useValidation = () => {
  const validationCache = useMemo(() => new Map(), [])

  const validateHeaders = useCallback((headers, overrideType, selectedFields) => {
    const requiredColumns = getRequiredColumns(overrideType, selectedFields)
    const errors = []
    const extraColumns = []

    // Check for required columns
    requiredColumns.forEach((column) => {
      if (!headers.has(column)) {
        errors.push(`Missing required column: ${column}`)
      }
    })

    // Find extra columns and filter them out from headers
    const allowedColumns = new Set(requiredColumns)
    const filteredHeaders = new Set()

    headers.forEach((header) => {
      if (allowedColumns.has(header)) {
        filteredHeaders.add(header)
      } else {
        extraColumns.push(header)
      }
    })

    const result = {
      isValid: errors.length === 0,
      errors,
      requiredColumns,
      extraColumns,
      headers: filteredHeaders, // Return filtered headers
    }

    return result
  }, [])

  const validateRows = useCallback(
    (rows, { overrideType, headers }) => {
      const headerSet = ensureHeaderSet(headers)
      const summary = createValidationSummary()
      const stateKeyToRows = new Map() // Map<`${productName}:${state}`, Set<{rowNumber, validationState}>>
      const countyKeyToRows = new Map() // Map<`${productName}:${state}:${county}`, Set<{rowNumber, validationState}>>
      const zipKeyToRows = new Map() // Map<`${productName}:${zip}`, Set<{rowNumber, validationState}>>
      const vendorKeyToRows = new Map() // Map<`${productName}:${vendor}`, Set<{rowNumber, validationState}>>
      const productGroups = new Map() // Map<productName, Array<{rowNumber, rowKey, row}>>
      const markedDuplicateRows = new Set()
      const availableColumns = {
        LOCATION: headerSet.has(FEE_MGMT_COLS_READABLE.LOCATION_TYPE),
        VENDOR: headerSet.has(FEE_MGMT_COLS_READABLE.VENDOR),
        FEE: headerSet.has(FEE_MGMT_COLS_READABLE.FEE),
        DUE_DATE: headerSet.has(FEE_MGMT_COLS_READABLE.DUE_DATE),
      }

      if (overrideType === 'fullValidation') {
        validationCache.clear()
      }
      // First collect product groups for duplicate row detection
      const rowLocationMap = new Map()
      createProductGroups(rows, availableColumns, productGroups, rowLocationMap)

      // Mark duplicate rows
      markDuplicateRows(productGroups, markedDuplicateRows)

      const processedRows = rows.map((row) => {
        const productName = row[FEE_MGMT_COLS_READABLE.PRODUCT_NAME] || ''
        const vendor = availableColumns.VENDOR ? row[FEE_MGMT_COLS_READABLE.VENDOR] : null
        const { states, counties, zipCodes } = rowLocationMap.get(row.rowNumber)

        // Create validation state
        const validationState = {
          isDuplicateRow: markedDuplicateRows.has(row.rowNumber),
          userMarkedForDelete: row.userMarkedForDelete || false,
          validationErrors: {},
          // TODO BANDREWS commenting this out for now -- don't think it's needed
          // locationType: availableColumns.LOCATION ? row[FEE_MGMT_COLS_READABLE.LOCATION_TYPE]?.toUpperCase() : null,
        }

        // Add to duplicate row summary if needed
        if (validationState.isDuplicateRow) {
          summary.errorRows[FeeValidationErrorTypes.DUPLICATE_ROW].add(row.rowNumber)
          return { validationState, row, states, counties, zipCodes, productName }
        }

        // Run missing value validations
        validateMissingValues({ ...row, states, counties, zipCodes }, validationState, summary, availableColumns)

        // Run format validations
        validateFormats({ ...row, states, counties, zipCodes }, validationState, summary)

        // Track valid locations for duplicate detection
        trackLocations(
          { validationState, row, states, counties, zipCodes, vendor, productName },
          { stateKeyToRows, countyKeyToRows, zipKeyToRows },
        )

        // Track vendors per product when no location columns exist
        trackVendors(validationState, vendor, productName, vendorKeyToRows, availableColumns)

        return { validationState, row, states, counties, zipCodes, productName }
      })

      // Check for duplicate locations after all rows are processed
      checkDuplicateLocations(stateKeyToRows, countyKeyToRows, zipKeyToRows, summary)

      // Check for duplicate vendors when no location columns exist
      checkDuplicateVendor(vendorKeyToRows, summary)

      // Create final row objects
      const finalRows = processedRows.map(({ validationState, row, states, counties, zipCodes }) => {
        const mappedRow = {
          rowNumber: row.rowNumber, // Preserve the row number
        }

        Object.values(FEE_MGMT_COLUMNS).forEach((column) => {
          if (row[column.readable]) {
            mappedRow[column.columnKey] = row[column.readable]
          }
        })

        const result = {
          ...mappedRow,
          isDuplicateRow: validationState.isDuplicateRow,
          userMarkedForDelete: validationState.userMarkedForDelete,
          validationErrors: validationState.validationErrors,
        }

        const rowKey = createRowKey({ ...row, states, counties, zipCodes }, availableColumns)
        const cacheKey = createCacheKey(row, rowKey)
        validationCache.set(cacheKey, result)

        return result
      })

      // Update error counts based on Set sizes
      Object.keys(summary.errorRows).forEach((errorType) => {
        summary.errorCounts[errorType] = summary.errorRows[errorType].size
      })

      summary.totalErrorCount = Object.values(summary.errorCounts).reduce((a, b) => a + b, 0)
      summary.hasErrors = summary.totalErrorCount > 0

      return { rows: finalRows, summary }
    },
    [validationCache],
  )

  return { validateRows, validateHeaders }
}
