import React, { useCallback, forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { Tooltip } from 'antd'

const IconHeader = forwardRef((props, ref) => {
  const { icon: Icon, tooltip, onClick, showMenu, column } = props

  const [isFilterActive, setIsFilterActive] = useState(false)
  const containerRef = useRef(null)

  useImperativeHandle(ref, () => ({
    getGui: () => containerRef.current,
  }))

  useEffect(() => {
    if (column?.addEventListener) {
      const onFilterChanged = () => {
        setIsFilterActive(column.isFilterActive())
      }

      column.addEventListener('filterChanged', onFilterChanged)
      // Set initial state
      setIsFilterActive(column.isFilterActive())

      return () => {
        column.removeEventListener('filterChanged', onFilterChanged)
      }
    }
  }, [column])

  const handleClick = useCallback(
    (event) => {
      onClick(event)
    },
    [onClick],
  )

  const onMenuClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (column) {
        props.showColumnMenu(containerRef.current)
      }
    },
    [props, column],
  )

  return (
    <div ref={containerRef} className="ag-header-cell" role="columnheader">
      <div className="ag-header-cell-comp-wrapper">
        <div className="ag-header-cell-label" role="presentation">
          <Tooltip title={tooltip}>
            <div className="header-icon">
              <Icon style={{ fontSize: '16px' }} onClick={handleClick} />
            </div>
          </Tooltip>
          {showMenu && (
            <div className="ag-header-cell-menu" onClick={onMenuClick}>
              <span
                className={`ag-icon ag-icon-filter ${isFilterActive ? 'ag-filter-active' : ''}`}
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

IconHeader.displayName = 'IconHeader'

export default IconHeader
