import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { logFormattedMessage } from '../../common/utils/consoleLogging'
import OverrideManager from './components/OverrideManager'
import ViewOverrideType from './components/ViewOverrideType'
import ViewOverrideContainer from './components/ViewOverrideContainer'
import CreateOverrideManager from './components/CreateOverrideManager'

const FeeManagementRoutes = () => {
  const location = useLocation()
  logFormattedMessage('FeeManagementRoutes rendering', { pathname: location.pathname })

  return (
    <Switch>
      <Route exact path="/fee-management" component={OverrideManager} />
      <Route exact path="/fee-management/overrides/:type" component={ViewOverrideType} />
      <Route exact path="/fee-management/overrides/:type/create" component={CreateOverrideManager} />
      <Route exact path="/fee-management/override/:id" component={ViewOverrideContainer} />
    </Switch>
  )
}

export default FeeManagementRoutes
