import OrderStatusBadge from '../widgets/OrderStatusBadge'
import moment from 'moment-business-days'
import momentRegular from 'moment'
import { Avatar, Tooltip } from 'antd'
import React, { Fragment } from 'react'
import axios from 'axios'
import FileSaver from 'file-saver'

import { RELATIVE_DATE_RANGES } from '../constants/queryBuilder'
import { JOB_TYPES } from '../constants/jobTypes'
import {
  APPRAISER_CANCELLATION_REQUEST,
  NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES,
  NO_ACCEPTED_APPRAISERS_AND_HAS_DENIED_COMPANIES,
  DEFAULT,
} from '../constants/alerts'

var store

// FIXME make it as middleware
export function configure(s) {
  store = s
}

export const downloadFile = (url, fileName) => {
  const token = localStorage.getItem('reggora_lender_auth_token')
  axios({
    headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    FileSaver.saveAs(new Blob([response.data]), fileName)
  })
}

export const downloadFileWithHeader = async (url) => {
  const token = localStorage.getItem('reggora_lender_auth_token')
  const response = await axios({
    headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  })
  FileSaver.saveAs(
    new Blob([response.data]),
    `${response.headers['x-report-lender-name']} accounting summary ${response.headers['x-report-last-updated']}.csv`,
  )
  return response
}

export const getBlob = async (rov) => {
  const token = localStorage.getItem('reggora_lender_auth_token')
  const response = await axios({
    headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
    url: `${API2_URL}evault/${rov.evault_id}/${rov.id}`,
    method: 'GET',
    responseType: 'blob', // important
  })
  return new Blob([response.data])
}

const colors = {
  Approved: 'success',
  Invited: 'success',
  Done: 'success',
  Completed: 'success',
  'Looking for Appraiser': 'warning',
  'Looking for Vendor': 'warning',
  'E&O outdated': 'warning',
  Pending: 'warning',
  Submitted: 'success',
  Assigned: 'info',
  'Inspection Completed': 'primary',
  'Inspection Scheduled': 'inspection-scheduled',
  'In Process of Appraising': 'primary',
  'Completing report': 'primary',
  Blacklisted: 'danger',
  Created: 'primary',
  Assigning: 'danger',
  Activated: 'primary',
  Cancelled: 'danger',
  Reassigned: 'danger',
  Hold: 'secondary',
  'Requires attention: no accepted appraiser': 'danger',
  'Requires attention: no accepted vendor': 'danger',
  Accepted: 'info',
  'Under Review': 'info',
  'Revision Requested': 'danger',
  'Lender Revisions Requested': 'danger',
  'Revision Submitted': 'warning',
  'Review Completed': 'success',
  'Review Pending': 'primary',
  'Waiting for Payment': 'warning',
}

export const createOrderRadios = [
  { label: 'Normal', value: 'Normal' },
  { label: 'Rush', value: 'Rush' },
]

export const jobAllocationRadios = [
  { label: 'Automatic', value: 'automatically' },
  { label: 'Manual', value: 'manually' },
]

export const roles = [
  {
    name: 'Realtor',
  },
  {
    name: 'Consumer',
  },
]

export const appraisal_type = [
  { label: 'Refinance', value: 'refinance' },
  { label: 'Purchase', value: 'purchase' },
  { label: 'Construction', value: 'construction' },
  { label: 'Other', value: 'other' },
]

export const states = [
  {
    short: 'AL',
    long: 'Alabama',
  },
  {
    short: 'AK',
    long: 'Alaska',
  },
  {
    short: 'AZ',
    long: 'Arizona',
  },
  {
    short: 'AR',
    long: 'Arkansas',
  },
  {
    short: 'CA',
    long: 'California',
  },
  {
    short: 'CO',
    long: 'Colorado',
  },
  {
    short: 'CT',
    long: 'Connecticut',
  },
  {
    short: 'DE',
    long: 'Delaware',
  },
  {
    short: 'FL',
    long: 'Florida',
  },
  {
    short: 'GA',
    long: 'Georgia',
  },
  {
    short: 'HI',
    long: 'Hawaii',
  },
  {
    short: 'ID',
    long: 'Idaho',
  },
  {
    short: 'IL',
    long: 'Illinois',
  },
  {
    short: 'IN',
    long: 'Indiana',
  },
  {
    short: 'IA',
    long: 'Iowa',
  },
  {
    short: 'KS',
    long: 'Kansas',
  },
  {
    short: 'KY',
    long: 'Kentucky',
  },
  {
    short: 'LA',
    long: 'Louisiana',
  },
  {
    short: 'ME',
    long: 'Maine',
  },
  {
    short: 'MD',
    long: 'Maryland',
  },
  {
    short: 'MA',
    long: 'Massachusetts',
  },
  {
    short: 'MI',
    long: 'Michigan',
  },
  {
    short: 'MN',
    long: 'Minnesota',
  },
  {
    short: 'MS',
    long: 'Mississippi',
  },
  {
    short: 'MO',
    long: 'Missouri',
  },
  {
    short: 'MT',
    long: 'Montana',
  },
  {
    short: 'NE',
    long: 'Nebraska',
  },
  {
    short: 'NV',
    long: 'Nevada',
  },
  {
    short: 'NH',
    long: 'New Hampshire',
  },
  {
    short: 'NJ',
    long: 'New Jersey',
  },
  {
    short: 'NM',
    long: 'New Mexico',
  },
  {
    short: 'NY',
    long: 'New York',
  },
  {
    short: 'NC',
    long: 'North Carolina',
  },
  {
    short: 'ND',
    long: 'North Dakota',
  },
  {
    short: 'OH',
    long: 'Ohio',
  },
  {
    short: 'OK',
    long: 'Oklahoma',
  },
  {
    short: 'OR',
    long: 'Oregon',
  },
  {
    short: 'PA',
    long: 'Pennsylvania',
  },
  {
    short: 'RI',
    long: 'Rhode Island',
  },
  {
    short: 'SC',
    long: 'South Carolina',
  },
  {
    short: 'SD',
    long: 'South Dakota',
  },
  {
    short: 'TN',
    long: 'Tennessee',
  },
  {
    short: 'TX',
    long: 'Texas',
  },
  {
    short: 'UT',
    long: 'Utah',
  },
  {
    short: 'VT',
    long: 'Vermont',
  },
  {
    short: 'VA',
    long: 'Virginia',
  },
  {
    short: 'WA',
    long: 'Washington',
  },
  {
    short: 'WV',
    long: 'West Virginia',
  },
  {
    short: 'WI',
    long: 'Wisconsin',
  },
  {
    short: 'WY',
    long: 'Wyoming',
  },
  {
    short: 'AS',
    long: 'American Samoa',
  },
  {
    short: 'DC',
    long: 'District of Columbia',
  },
  {
    short: 'FM',
    long: 'Federated States of Micronesia',
  },
  {
    short: 'GU',
    long: 'Guam',
  },
  {
    short: 'MH',
    long: 'Marshall Islands',
  },
  {
    short: 'MP',
    long: 'Northern Mariana Islands',
  },
  {
    short: 'PW',
    long: 'Palau',
  },
  {
    short: 'PR',
    long: 'Puerto Rico',
  },
  {
    short: 'VI',
    long: 'Virgin Islands',
  },
]

export const allStatesShort = states.map((st) => st.short)
export const allStatesLong = states.map((st) => st.long)

export const convertStateToShort = (longName) => {
  const stateObj = states.find((state) => state.long === longName)
  return stateObj ? stateObj.short : longName
}

export const convertStateToLong = (shortName) => {
  const stateObj = states.find((state) => state.short === shortName)
  return stateObj ? stateObj.long : shortName
}

export function utcToLocal(datetime) {
  return moment.utc(datetime).local()
}

export function checkTableData(time) {
  if (time === 'no data' || !moment(time).isValid()) {
    return ''
  }
  return utcToLocal(time).format('MM/DD/YYYY')
}

export function checkTableDataWithTime(time) {
  if (time === 'no data' || !moment(time).isValid()) {
    return ''
  }
  return utcToLocal(time).format('MM/DD/YYYY hh:mm A')
}

export function getUTCTime(time) {
  if (time === 'no data' || !moment(time).isValid()) {
    return ''
  }
  return moment(time).format('MM/DD/YYYY')
}

export function renderStatusBadge(status, is_amc_lender, item) {
  const {
    on_hold,
    appraiser_requested_cancel,
    lender_canceled,
    job_type,
    client_lender_revision_status,
    amc_revision_status,
    reassigned,
  } = item
  if (status === 'Pending Review') {
    status = 'Submitted'
  }

  // We theoretically should never be showing reassigned orders in the table for this to happen
  if (reassigned && (job_type !== JOB_TYPES.AMC || is_amc_lender)) {
    return <OrderStatusBadge color={'danger'} status={'Reassigned'} />
  }

  // If an order is cancelled then we want to always show that it is cancelled.
  if (lender_canceled) {
    return <OrderStatusBadge color={'danger'} status={'Cancelled'} />
  }

  if (is_amc_lender && amc_revision_status) {
    return <OrderStatusBadge color={colors[amc_revision_status]} status={amc_revision_status} />
  } else if (!is_amc_lender && client_lender_revision_status) {
    return <OrderStatusBadge color={colors[client_lender_revision_status]} status={client_lender_revision_status} />
  }

  if (appraiser_requested_cancel && (job_type !== JOB_TYPES.AMC || is_amc_lender)) {
    return <OrderStatusBadge color={'danger'} status={'Requested Cancellation'} />
  }

  if (on_hold) {
    status = 'Hold'
  }

  if (!status || status === 'no data') {
    return ''
  }
  return <OrderStatusBadge color={colors[status]} status={status} />
}

export function convertToUTCDate(datetime) {
  const datetimeObj = moment(datetime)
  const utcObj = moment().utc().set({
    year: datetimeObj.year(),
    month: datetimeObj.month(),
    date: datetimeObj.date(),
    hour: 12,
    minute: 0,
    second: 0,
  })
  return utcObj.format()
}

export function utcNow() {
  return moment.utc(moment()).format('YYYY-MM-DD HH:mm:ss.SSS')
}

export function businessDaysFromMoment(datetime, daysFrom) {
  return moment(datetime).businessAdd(daysFrom)
}

// get the user system's IANA timezone
// in-depth explanation here: http://tinyurl.com/ycou22ce (stackoverflow)
export function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const buildAvatars = (users) => {
  const tooltips = []
  let plus = ''
  const overflow = []

  if (!users) {
    return []
  }

  const numberOfBubbles = 3

  if (users.length > numberOfBubbles) {
    plus = `+${users.length - numberOfBubbles}`
  }

  users.forEach((user, idx) => {
    const fullname = `${user.firstname || ''} ${user.lastname || ''}`
    if (idx <= numberOfBubbles - 1) {
      tooltips.push(
        <Tooltip title={fullname} key={user.id}>
          <Avatar className="mx-1">
            {user.firstname ? user.firstname[0] : ''}
            {user.lastname ? user.lastname[0] : ''}
          </Avatar>
        </Tooltip>,
      )
    } else {
      overflow.push(
        <Fragment key={user.id}>
          {fullname}
          <br />
        </Fragment>,
      )
    }
  })

  if (overflow.length) {
    tooltips.push(
      <Tooltip title={<div>{overflow}</div>} key="overflow">
        <Avatar className="m-15">{plus}</Avatar>
      </Tooltip>,
    )
  }

  return tooltips
}

export const apiToReadable = (str) => {
  if (str === 'ordering') {
    return 'Send Payment Link to Borrower Before Ordering'
  } else if (str === 'ssr') {
    return 'SSR'
  } else {
    return str
      .split('_')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ')
  }
}
export const readableToApi = (str) => str.toLowerCase().split(' ').join('_')

export const checkPermission = (permissionName) => {
  // Check if the user is logged in
  const { resource: { user: { data } = {} } = {} } = store.getState()
  if (!data || (data && !data.role)) {
    return false
  }

  // If they are logged in, grab the permissions object
  const { role: { permissions } = {} } = data
  if (!permissions) {
    return false
  }
  if (permissions[permissionName] === undefined) {
    if (permissionName === 'fee-management_route') {
      // TODO BANDREWS remove this
      return true
    }
    throw Error(`Invalid permission name: ${permissionName}`)
  }
  return permissions[permissionName].allowed
}

// check if the user has permission to view order conversations on orders
export const checkCanViewOrderConversation = (order) => {
  let hasPermission = false

  if (order) {
    // if it is a VA order, check the va specific permission
    if (order.job_type === 'va') {
      hasPermission = checkPermission('view_va_order_conversation')
    } else {
      hasPermission = checkPermission('view_order_conversation')
    }
  }

  return hasPermission
}

// check if the user has permission to participate in order conversations on orders
export const checkCanParticipateInOrderConversation = (order) => {
  let hasPermission = false

  if (order) {
    // if it is a VA order, check the va specific permission
    if (order.job_type === 'va') {
      hasPermission = checkPermission('participate_va_conversation')
    } else {
      hasPermission = checkPermission('participate_conversation')
    }
  }

  return hasPermission
}

// check if the user has permission to view appraiser(s') name on orders
export const checkCanViewAppraiserNameOnOrder = (order) => {
  let hasPermission = false

  if (order) {
    // if it is a VA order, check the VA specific permission
    if (order.job_type === 'va') {
      hasPermission = checkPermission('user_view_va_appraiser')
    } else {
      hasPermission = checkPermission('user_view_appraiser')
    }
  }

  return hasPermission
}

// check if user has permission(s) to respond to counter offer
export const checkCounterOfferPermissions = (order, offer) => {
  const hasFeePermission = checkPermission('counter_offer_accept_fee')
  const hasDueDatePermission = checkPermission('counter_offer_accept_due_date')

  if (hasFeePermission && hasDueDatePermission) {
    return true
  } else if (!hasFeePermission && !hasDueDatePermission) {
    return false
  } else {
    // user has 1 of the 2 permissions, so we need to check what offer is changing

    if (offer.fee_delta && !hasFeePermission) {
      // offer changes fee, but user lacks permission
      return false
    }

    if (offer.due_date !== order.due_date && !hasDueDatePermission) {
      // offer changes due date, but user lacks permission
      return false
    }

    // user has permission for whatever the offer changes
    return true
  }
}

export const checkUserPermission = (permissionName, userObject) => {
  const { role: { permissions } = {} } = userObject

  if (!permissions) {
    return false
  }

  // If the permission we are checking for doesnt exist throw an error
  // so that a developer can identify it
  if (permissions[permissionName] === undefined) {
    throw Error(`Invalid permission name: ${permissionName}`)
  }

  // Otherwise return whatever the lender has specified for that permission
  return permissions[permissionName].allowed
}

export const splitListIntoHalves = (list) => {
  let half1 = []
  let half2 = []

  if (list) {
    const length = list.length
    const startingIndex = length % 2 === 0 ? Math.floor(length / 2) : Math.floor(length / 2) + 1
    half1 = [...list]
    half2 = half1.splice(startingIndex, length - 1)
  }

  return {
    half1,
    half2,
  }
}

// adjust the input original delay (original_hours) based on the start and end hours set by the lender
export const calculate_adjusted_delay = (
  original_hours,
  start_date,
  start_hour,
  end_hour,
  work_on_saturday = false,
  work_on_sunday = false,
) => {
  let additional_hours = original_hours
  const start_date_timezone_adjusted = moment(utcToLocal(start_date))

  const hours = original_hours % 24
  // get the hour that the request expires
  const expire_hour = start_date_timezone_adjusted.hours() + hours
  // check if the request expires after the lender's business hours
  if (expire_hour >= end_hour) {
    // calculate the hours we might need to add
    const hours_to_add = 24 - expire_hour + start_hour
    // add the carry over hours if they're positive, otherwise the original original_hours carries over to the following business day on its own
    if (hours_to_add > -1) {
      additional_hours += hours_to_add
      // subtract minutes from the new time limit so that we don't over shoot the start of business day
      additional_hours -= start_date_timezone_adjusted.minutes() / 60
    }
  }
  // need to check if the new calculated original_hours falls onto the weekend and adjust according to lender weekend settings
  const expire_datetime = start_date_timezone_adjusted.add(additional_hours, 'hours')
  // returns day of the week as an integer. Sunday = 0, Monday = 1, Saturday = 6
  const day_of_the_week = expire_datetime.days()
  if (day_of_the_week === 6 && !work_on_saturday) {
    additional_hours += 24
    if (!work_on_sunday) {
      additional_hours += 24
    }
  } else if (day_of_the_week === 0 && !work_on_sunday) {
    additional_hours += 24
  }

  return additional_hours
}

export const checkLowValuation = (submission, loan) => {
  const estimatedValue = loan.estimated_value || 0
  const purchasePrice = loan.purchase_price || 0
  const maxLoanValue = estimatedValue > purchasePrice ? estimatedValue : purchasePrice
  return submission.appraised_value && maxLoanValue && maxLoanValue > submission.appraised_value
}

export const ext = (filename) => {
  return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
}

export const toCapitalizedWords = (name) => {
  const words = name.match(/[A-Za-z][a-z]*/g) || []

  return words.map(capitalize).join(' ')
}

export const capitalize = (word) => word.charAt(0).toUpperCase() + word.substring(1)

export const shortenStringWithEllipsis = (string, maxCharacters = 20) => {
  let finalString = string
  if (string && string.length > maxCharacters) {
    finalString = string.slice(0, maxCharacters) + '...'
  }
  return finalString
}

export const getRelativeDateFromDateMath = (dateMathString) => {
  const today = momentRegular()
  let result = ''

  if (RELATIVE_DATE_RANGES[0].value === dateMathString) {
    result = today.add(30, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[1].value === dateMathString) {
    result = today.add(14, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[2].value === dateMathString) {
    result = today.add(7, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[3].value === dateMathString) {
    result = today.add(6, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[4].value === dateMathString) {
    result = today.add(5, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[5].value === dateMathString) {
    result = today.add(4, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[6].value === dateMathString) {
    result = today.add(3, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[7].value === dateMathString) {
    result = today.add(2, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[8].value === dateMathString) {
    result = today.add(1, 'd').format('dddd, MMMM Do hA')
  } else if (RELATIVE_DATE_RANGES[9].value === dateMathString) {
    result = today.add(12, 'h').format('dddd, MMMM Do hA')
  } else if (RELATIVE_DATE_RANGES[10].value === dateMathString) {
    result = 'today'
  } else if (RELATIVE_DATE_RANGES[11].value === dateMathString) {
    result = today.subtract(12, 'h').format('dddd, MMMM Do hA')
  } else if (RELATIVE_DATE_RANGES[12].value === dateMathString) {
    result = today.subtract(1, 'd').format('dddd, MMMM Do hA')
  } else if (RELATIVE_DATE_RANGES[13].value === dateMathString) {
    result = today.subtract(2, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[14].value === dateMathString) {
    result = today.subtract(3, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[15].value === dateMathString) {
    result = today.subtract(4, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[16].value === dateMathString) {
    result = today.subtract(5, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[17].value === dateMathString) {
    result = today.subtract(6, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[18].value === dateMathString) {
    result = today.subtract(7, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[19].value === dateMathString) {
    result = today.subtract(14, 'd').format('dddd, MMMM Do')
  } else if (RELATIVE_DATE_RANGES[20].value === dateMathString) {
    result = today.subtract(30, 'd').format('dddd, MMMM Do')
  }

  // if we couldn't get a match for some reason. lets default to the date math readable
  if (!result) {
    for (const item of RELATIVE_DATE_RANGES) {
      if (item.value === dateMathString) {
        result = item.label
        break
      }
    }
  }

  return result
}

export const attentionRequiredIsAllocation = (lender_attention_required, lender_attention_required_type) => {
  const allocationTypes = [
    APPRAISER_CANCELLATION_REQUEST,
    NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES,
    NO_ACCEPTED_APPRAISERS_AND_HAS_DENIED_COMPANIES,
    DEFAULT,
  ]

  return lender_attention_required && allocationTypes.indexOf(lender_attention_required_type) > -1
}

/*
  - getNotificationText returns a dynamic string based on the notification_type and user permissions. For example, we are using some helpers to filter out appraiser names on some of the notification text.
  i.e. "user_view_appraiser" vs "user_view_va_appraiser" in checkCanViewAppraiserNameOnOrder
  - The data/argument 'notification' is coming from HOC AllNotifications component
*/
export const getNotificationText = (notification) => {
  const { initiator, notification_type, object_delta } = notification

  let order_obj = {}
  // Set order_obj from the backend for cases where we need more order info
  if (object_delta && 'order' in object_delta) {
    order_obj = object_delta.order
  }

  switch (notification_type) {
    case 'appraiser_license_expiration':
      return `The appraiser ${object_delta.appraiser_name}'s \
license has expired. They are a member of ${object_delta.lender_company_name}.`
    case 'order_loan_change':
      return `The loan file for the order at ${object_delta.address} has been changed by the lender.`
    case 'check_status_update':
      return `The check sent to ${object_delta.recipient_name} for \
an order at ${object_delta.order_address} has been updated from ${object_delta.old_check_status} \
to ${object_delta.new_check_status}.`
    case 'review_appraiser_assigned':
      return `You have been requested to review an order at ${object_delta.order_address}.`
    case 'appraisal_valuation_low':
      return `Loan #${object_delta.loan_number} was appraised at $${object_delta.appraisal_value}, \
which is lower than the ${object_delta.larger_name} ($${object_delta.larger_value})`
    case 'appraisal_sent_to_borrower':
      return `Appraisal has been sent to ${object_delta.recipient_info}. Appraisal was sent to ${object_delta.recipients} on ${object_delta.delivery_date}`
    case 'consumer_submission_download_alerts':
      return `Your order at ${object_delta.order_address} was never downloaded by the borrower.`
    case 'external_review_ready':
      return `Your order at ${object_delta.order_address} has a new external review result.`
    case 'consumer_payment_error':
      return `There was an issue processing a payment for your order at ${object_delta.address}`
    case 'order_approved':
      return `Your order at ${object_delta.address} has been completed.`
    case 'order_reapproved':
      return `A revised appraisal has been submitted for your order at ${object_delta.address}.`
    case 'order_unapproved':
      return `Your order at ${object_delta.address} has been unapproved.`
    case 'amc_order_created':
      return `We have been requested for an order at ${object_delta.address} by ${object_delta.client}.`
    case 'order_revision_request_amc_required':
      return `${object_delta.lender_name} requested a revision at ${object_delta.address}.`
    case 'order_requires_approval':
      return `An order at ${object_delta.address} requires your approval`
    case 'pending_order_approved':
      return `Your pending order at ${object_delta.address} has been approved.`
    case 'pending_order_declined':
      return `Your pending order at ${object_delta.address} has been declined.`
    case 'order_hold_change':
    case 'order_placed_on_hold': {
      const onHoldExplanation = object_delta.on_hold ? 'set on hold' : 'removed from hold'
      return `Your order at ${object_delta.address} has been ${onHoldExplanation}.`
    }
    case 'order_conversation_message_no_view':
      if (object_delta && object_delta.order_address) {
        if (!checkCanViewAppraiserNameOnOrder(order_obj)) {
          return 'You have received a new message in an order.'
        }
        return `${initiator.firstname} ${initiator.lastname} has sent you a new message in an order.`
      } else {
        return 'You have received a new message in an order.'
      }
    case 'asynch_charge_error':
      return `An error occurred while attempting to charge a consumer's card for your pending order at ${object_delta.address}`
    case 'order_conversation_message':
      if (object_delta.is_revision) {
        if (object_delta.order_address) {
          if (!checkCanViewAppraiserNameOnOrder(order_obj)) {
            return `A revision has been requested for your order ${object_delta.order_address}.`
          }
          return `${initiator.firstname} ${initiator.lastname} has requested \
          a revision for your order ${object_delta.order_address}.`
        } else {
          if (!checkCanViewAppraiserNameOnOrder(order_obj)) {
            return 'You have received a new message in an order.'
          }

          return `${initiator.firstname} ${initiator.lastname} has sent you a new message in an order.`
        }
      } else {
        if (object_delta.order_address) {
          if (!checkCanViewAppraiserNameOnOrder(order_obj)) {
            return `You have received a new message for your order ${object_delta.order_address}.`
          }
          return `${initiator.firstname} ${initiator.lastname} has sent you a new message \
for your order ${object_delta.order_address}.`
        } else {
          if (!checkCanViewAppraiserNameOnOrder(order_obj)) {
            return 'You have received a new message in an order.'
          }
          return `${initiator.firstname} ${initiator.lastname} has sent you a new message in an order.`
        }
      }
    case 'order_status_change_no_company':
    case 'order_status_change':
      if (object_delta.company && checkCanViewAppraiserNameOnOrder(order_obj)) {
        return `${object_delta.company} changed the status for your order ${object_delta.order_address} from ${object_delta.old_status === 'Pending Review' ? 'Submitted' : object_delta.old_status} to ${object_delta.new_status === 'Pending Review' ? 'Submitted' : object_delta.new_status}`
      } else if (object_delta.order_address) {
        return `Your order ${object_delta.order_address} changed from ${object_delta.old_status === 'Pending Review' ? 'Submitted' : object_delta.old_status} to ${object_delta.new_status === 'Pending Review' ? 'Submitted' : object_delta.new_status}`
      } else {
        return `An order has changed to status ${object_delta.new_status}`
      }
    // case 'order_company_assignment':
    // case 'order_ready_for_approval':
    // templateType = 'order_ready_for_approval';
    // matchData = {
    // '{{order_address}}': object_delta.order_address
    // }
    case 'order_note_created':
      if (object_delta.order_address) {
        return `A note was created for your order ${object_delta.order_address}.`
      }
      return 'A note was created for your order.'
    case 'order_offer':
    case 'order_counter_offer':
    case 'order_offer_no_company':
      if (object_delta.company_name && checkCanViewAppraiserNameOnOrder(order_obj)) {
        return `${object_delta.company_name} has sent you a counter offer for your order at ${object_delta.address}`
      } else {
        return `Your order ${object_delta.address} has received a counter offer from the requested appraisal company`
      }
    case 'evault_upload':
      return `The appraiser has uploaded a document to your order at ${object_delta.address}`
    case 'waiting_for_payment_complete':
      return `Your order ${object_delta.order_address} changed from ${object_delta.old_status} to ${object_delta.new_status}.`
    case 'order_behind_schedule':
      return `Your order at ${object_delta.address} is behind schedule.`
    case 'order_company_assignment_decline_no_company':
    case 'order_company_assignment_decline':
      if (object_delta.company && checkCanViewAppraiserNameOnOrder(order_obj)) {
        return `${object_delta.company} has declined your order ${object_delta.order_address}.`
      } else {
        return 'One of your appraisers has declined an order.'
      }
    case 'order_in_progress':
      if (object_delta.company && checkCanViewAppraiserNameOnOrder(order_obj)) {
        return `${object_delta.company} has begun a report for your order ${object_delta.order_address}.`
      } else {
        return 'An appraiser has begun a report on one of your orders.'
      }
    case 'order_inspection_date_update': {
      const date = utcToLocal(object_delta.inspection_date).format('LLL')
      return `The inspection for your order ${object_delta.order_address} has been scheduled for ${date}.`
    }
    case 'report_downloaded':
      return `${object_delta.consumer.full_name || object_delta.consumer.firstname + object_delta.consumer.lastname} \
has downloaded the report for your order ${object_delta.order_address}.`
    case 'electronic_consent_denied':
      return `${object_delta.consumer.full_name || object_delta.consumer.firstname + object_delta.consumer.lastname} \
has denied electronic consent for your order ${object_delta.order_address}.`
    case 'order_payment_incomplete':
      if (object_delta.address) {
        return `Payment has not yet been completed for your order ${object_delta.address}`
      } else {
        return 'Payment has not yet been completed for an order.'
      }
    case 'order_payment_complete':
      if (object_delta.order_address) {
        return `Payment has been completed for your order ${object_delta.order_address}`
      } else {
        return 'Payment has been completed for an order.'
      }
    case 'order_cancel_request':
      if (object_delta.order_address) {
        return `A cancellation request has been made for your order ${object_delta.order_address}`
      } else {
        return 'A cancellation request has been made for an order.'
      }
    case 'order_appraisal_report_ready':
      return `The appraisal report ${object_delta.document_name} \
has been completed for your order ${object_delta.order_address}`
    case 'no_notifications':
      return 'You do not have any notifications'
    case 'no_messages':
      return 'You do not have any messages'
    case 'order_requires_lender_attention':
      return `Your order at ${object_delta.order_address} requires your attention.`
    case 'order_internal_note_created':
      return `A new note was created for your order at ${object_delta.order_address}`
    case 'va_credentials_expiration':
      return 'Your VA credentials have expired. Please update in settings'
    case 'payment_link_sent':
      return `Payment link was sent for your order at ${object_delta.address}.`
    case 'all_appraiser_reinvites_complete':
      return 'All reinvites for pending appraisers have completed.'
    default:
      return 'There was an error processing your notification.'
  }
}

export const alwaysDisableVendorSelection = (appraiser) => {
  return (
    !appraiser.accepting_jobs ||
    !!appraiser.is_blacklisted ||
    !!appraiser.has_expired_eo ||
    !!appraiser.is_out_of_office
  )
}

export const overrideVendorSelection = (appraiser) => {
  return appraiser.has_expired_license && !checkPermission('override_vendor_selection')
}

export const showBadVendorReason = (appraiser) => {
  return appraiser && appraiser.is_bad_vendor && appraiser.bad_vendor_reasons && appraiser.bad_vendor_reasons.length
}

export const orderHasCompanyDirectPayment = (order) => {
  if (order.schedule && order.schedule.payments) {
    for (const consumerPayment of order.schedule.payments) {
      if (consumerPayment.direct_appraisal_company || consumerPayment.transaction_id) {
        return true
      }
    }
  }
  return false
}

export const ellieMaeRequest = async (path, method = 'get') => {
  const token = localStorage.getItem('reggora_lender_auth_token')
  const response = await axios({
    headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
    url: `${ELLIE_MAE_SERVICE_URL}${path}`,
    method,
  })
  return response.data
}

const OUT_OF_OFFICE_DISPLAY_FORMAT = 'M/D'

export const getOutOfOfficeMessage = (appraiser) => {
  const getOutOfOfficeRangeDisplay = (range) => {
    let display = `from ${momentRegular(range.start).format(OUT_OF_OFFICE_DISPLAY_FORMAT)} `
    if (range.end) {
      display += `to ${momentRegular(range.end).format(OUT_OF_OFFICE_DISPLAY_FORMAT)}`
    } else {
      display += 'until further notice'
    }
    return display
  }

  const getOutOfOfficeRangesDisplay = (ranges) => ranges.map(getOutOfOfficeRangeDisplay).join(', ')

  let outOfOfficeMessage = null
  if (appraiser && appraiser.out_of_office_ranges && appraiser.out_of_office_ranges.length) {
    const outOfOfficeRanges = appraiser.out_of_office_ranges
    if (appraiser.is_out_of_office) {
      const currentOutOfOfficeEnd = outOfOfficeRanges[0].end
        ? momentRegular(outOfOfficeRanges[0].end).format(OUT_OF_OFFICE_DISPLAY_FORMAT)
        : undefined
      outOfOfficeMessage = `Out of office ${currentOutOfOfficeEnd ? `from now to ${currentOutOfOfficeEnd}` : 'until further notice'}`
      const futureOutOfOffices = getOutOfOfficeRangesDisplay(outOfOfficeRanges.slice(1))
      if (futureOutOfOffices) {
        outOfOfficeMessage += `, ${futureOutOfOffices}`
      }
    } else {
      const futureOutOfOffices = getOutOfOfficeRangesDisplay(outOfOfficeRanges)
      outOfOfficeMessage = `Will be out of office ${futureOutOfOffices}`
    }
  }
  return outOfOfficeMessage
}

export const getRedirectLink = () => {
  const routes = {
    orders_route: '/orders',
    loans_route: '/loan-files/',
    appraisers_route: '/appraisers',
    exclusionaryList_route: '/appraisers/exclusionaryList',
    fee_route: '/fee',
    branches_route: '/branches',
    users_route: '/users',
    userRoles_route: '/users/roles',
    accounting_route: '/accounting',
    admin_settings_route: '/admin_settings',
    dashboard_route: '/dashboard',
  }

  let redirect_endpoint = '/profile/main'
  for (const [hasPermission, endpoint] of Object.entries(routes)) {
    if (checkPermission(hasPermission)) {
      redirect_endpoint = endpoint
      return redirect_endpoint
    }
  }
  return redirect_endpoint
}

export const getRUCCTypeFromCode = (ruccCode) => {
  if ([1, 2, 3].includes(ruccCode)) {
    return 'Metro'
  } else if ([4, 5, 6, 7, 8, 9].includes(ruccCode)) {
    return 'Non-metro'
  }
  return 'Not applicable'
}
