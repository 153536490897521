import React, { createContext, useContext } from 'react'

const OverrideContext = createContext()

export const OverrideProvider = ({ children, value }) => (
  <OverrideContext.Provider value={value}>{children}</OverrideContext.Provider>
)

export const useOverride = () => {
  const context = useContext(OverrideContext)
  if (!context) {
    throw new Error('useOverride must be used within an OverrideProvider')
  }
  return context
}
