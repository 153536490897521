import React from 'react'
import PropTypes from 'prop-types'
import { WarningOutlined } from '@ant-design/icons'
import GridControlsSection from './GridControlsSection'
import GridControlsValidationPills from './GridValidationPills'
import { logFormattedMessage } from '../../common/utils/consoleLogging'

const ValidationSummarySection = ({
  validationResults,
  selectedErrorTypes,
  onToggleErrorType,
  validationConfig,
  onValidationFilterChange,
}) => {
  const handleToggleErrorType = (errorType) => {
    logFormattedMessage('ValidationSummarySection handleToggleErrorType', {
      errorType,
      hasValidationFilterChange: !!onValidationFilterChange,
      currentlySelected: selectedErrorTypes.has(errorType),
      validationResults: validationResults[errorType],
    })

    const willBeSelected = !selectedErrorTypes.has(errorType)
    onToggleErrorType(errorType)
    onValidationFilterChange(errorType, willBeSelected)
  }

  return (
    <GridControlsSection title="Validation Summary" icon={WarningOutlined} className="validation-summary">
      <GridControlsValidationPills
        validationResults={validationResults}
        selectedErrorTypes={selectedErrorTypes}
        onToggleErrorType={handleToggleErrorType}
        validationConfig={validationConfig}
      />
    </GridControlsSection>
  )
}

ValidationSummarySection.propTypes = {
  validationResults: PropTypes.objectOf(PropTypes.number).isRequired,
  selectedErrorTypes: PropTypes.instanceOf(Set).isRequired,
  onToggleErrorType: PropTypes.func.isRequired,
  validationConfig: PropTypes.object.isRequired,
  onValidationFilterChange: PropTypes.func.isRequired,
}

export default ValidationSummarySection
