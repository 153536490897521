import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ModuleRegistry, AllCommunityModule, ClientSideRowModelModule } from 'ag-grid-community'
import PropTypes from 'prop-types'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { logFormattedMessage } from '../../common/utils/consoleLogging'

// Register the required modules
ModuleRegistry.registerModules([AllCommunityModule, ClientSideRowModelModule])

const Grid = ({ rowData, columnDefs, defaultColDef, context, gridApiRef, onCellValueChanged, onFilterChanged }) => {
  logFormattedMessage('Grid rendering', {
    rowCount: rowData?.length,
    columnCount: columnDefs?.length,
    columns: columnDefs?.map((c) => c.field),
    sampleRow: rowData?.[0],
  })

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: '500px',
        width: '100%',
        position: 'relative',
        '--ag-font-family': "'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif",
        '--ag-font-size': '14px',
        '--ag-grid-size': '4px',
        '--ag-row-height': '48px',
        '--ag-header-height': '48px',
        '--ag-header-foreground-color': 'rgba(0, 0, 0, 0.85)',
        '--ag-foreground-color': 'rgba(0, 0, 0, 0.85)',
        '--ag-border-color': '#d9d9d9',
        '--ag-row-border-color': '#d9d9d9',
        '--ag-row-hover-color': '#f0f0f0',
        '--ag-selected-row-background-color': '#e6f7ff',
        '--ag-header-background-color': '#f5f5f5',
        '--ag-odd-row-background-color': '#ffffff',
        '--ag-header-column-separator-display': 'none',
        '--ag-cell-horizontal-padding': '16px',
      }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        context={context}
        onGridReady={(params) => {
          if (gridApiRef) {
            gridApiRef.current = params.api
          }
        }}
        onCellValueChanged={onCellValueChanged}
        onFilterChanged={onFilterChanged}
        suppressColumnMoveAnimation={true}
        rowHeight={48}
        headerHeight={48}
        enableCellTextSelection={true}
        getRowId={(params) => String(params.data.rowNumber)}
        suppressMenuHide={false}
        alwaysMultiSort={true}
        rowClassRules={{
          'row-marked-for-delete': (params) => params.data?.userMarkedForDelete || params.data?.isDuplicateRow,
          'row-has-error': (params) =>
            params.data?.validationErrors && Object.keys(params.data.validationErrors).length > 0,
        }}
      />
    </div>
  )
}

Grid.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultColDef: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  gridApiRef: PropTypes.object,
  onCellValueChanged: PropTypes.func,
  onFilterChanged: PropTypes.func,
}

export default Grid
