import { GridValidationErrorTypes } from '../../../common/constants/ValidationTypes'

export const ZIP_REGEX = /^\d{5}$/
export const STATE_REGEX = /^[A-Z]{2}$/
export const COUNTY_REGEX = /^[A-Za-z\s-]+$/

const DELETE_COL = {
  readable: 'Delete',
  columnKey: 'delete',
}
const ERROR_COL = {
  readable: 'Error',
  columnKey: 'error',
}
const PRODUCT_NAME_COL = {
  readable: 'Product Name',
  columnKey: 'productName',
}
const LOCATION_TYPE_COL = {
  readable: 'Location Type',
  columnKey: 'locationType',
}
const VENDOR_COL = {
  readable: 'Vendor',
  columnKey: 'vendor',
}
const STATE_COL = {
  readable: 'States',
  columnKey: 'states',
}
const COUNTY_COL = {
  readable: 'Counties',
  columnKey: 'counties',
}
const ZIP_CODE_COL = {
  readable: 'Zip Codes',
  columnKey: 'zipCodes',
}
const FEE_COL = {
  readable: 'Fee',
  columnKey: 'fee',
}
const DUE_DATE_COL = {
  readable: 'Due Date',
  columnKey: 'dueDate',
}

const DEFAULT_GRID_COLUMN_OPTIONS = {
  shouldSplitCommaValues: true,
  excludeInCsvExport: true,
}

const DEFAULT_VALIDATION_OPTIONS = {
  existsInFile: true,
  supportsMultiValue: true,
}

export const FEE_MGMT_COLUMNS = {
  DELETE: {
    ...DELETE_COL,
    context: {
      validation: {
        existsInFile: false,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        excludeInCsvExport: false,
      },
    },
  },
  ERROR: {
    ...ERROR_COL,
    context: {
      validation: {
        existsInFile: false,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        excludeInCsvExport: false,
      },
    },
  },
  PRODUCT_NAME: {
    ...PRODUCT_NAME_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  VENDOR: {
    ...VENDOR_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        excludeInCsvExport: true,
      },
    },
  },
  LOCATION_TYPE: {
    ...LOCATION_TYPE_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        excludeInCsvExport: true,
      },
    },
  },
  STATES: {
    ...STATE_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  COUNTIES: {
    ...COUNTY_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  ZIP_CODES: {
    ...ZIP_CODE_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  FEE: {
    ...FEE_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        excludeInCsvExport: true,
      },
    },
  },
  DUE_DATE: {
    ...DUE_DATE_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        excludeInCsvExport: true,
      },
    },
  },
}

export const FEE_MGMT_COLS_READABLE = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[key] = FEE_MGMT_COLUMNS[key].readable
  return acc
}, {})

export const FEE_MGMT_COLS_BY_READABLE = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[FEE_MGMT_COLUMNS[key].readable] = key
  return acc
}, {})

export const ROW_NUMBER_COL = {
  readable: 'Row #',
  columnKey: 'rowNumber',
  context: {
    validation: {
      existsInFile: false,
      supportsMultiValue: false,
    },
    customGridColumnOptions: {
      shouldSplitCommaValues: false,
      excludeInCsvExport: false,
    },
  },
}

export const REQUIRED_LOCATION_COLUMNS = [
  LOCATION_TYPE_COL.readable,
  STATE_COL.readable,
  COUNTY_COL.readable,
  ZIP_CODE_COL.readable,
]
export const REQUIRED_VENDOR_COLUMNS = [VENDOR_COL.readable]

export const REQUIRED_COLUMNS_BY_OVERRIDE_TYPE = {
  common: [PRODUCT_NAME_COL.readable],
  location: REQUIRED_LOCATION_COLUMNS,
  vendor: REQUIRED_VENDOR_COLUMNS,
  vendorLocation: [...REQUIRED_VENDOR_COLUMNS, ...REQUIRED_LOCATION_COLUMNS],
}

export const OPTIONAL_COLUMNS = [FEE_COL.readable, DUE_DATE_COL.readable]

const DUPLICATE_STATE = 'DUPLICATE_STATE'
const DUPLICATE_COUNTY = 'DUPLICATE_COUNTY'
const DUPLICATE_ZIP = 'DUPLICATE_ZIP'
const DUPLICATE_VENDOR = 'DUPLICATE_VENDOR'
const INVALID_FIELD_FOR_LOCATION_TYPE = 'INVALID_FIELD_FOR_LOCATION_TYPE'

// Extend grid validation types with domain-specific ones
export const FeeValidationErrorTypes = {
  ...GridValidationErrorTypes,
  INVALID_FIELD_FOR_LOCATION_TYPE,
  DUPLICATE_STATE,
  DUPLICATE_COUNTY,
  DUPLICATE_ZIP,
  DUPLICATE_VENDOR,
}

const PRODUCT = 'PRODUCT'
const LOCATION = 'LOCATION'
const VENDOR = 'VENDOR'

export const OVERRIDE_TYPES = { PRODUCT, LOCATION, VENDOR }

const STATE = 'MULTI-STATE'
const COUNTY = 'COUNTY'
const ZIP = 'ZIP'

export const LOCATION_TYPES = { STATE, COUNTY, ZIP }
