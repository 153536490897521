import React from 'react'
import { Button, Upload, Progress } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const FileUpload = ({ onFileUpload, onClear, isProcessing, progress, timeRemaining, formatTimeRemaining }) => {
  return (
    <div className="header-controls">
      <div className="left-controls">
        <Upload
          accept=".csv"
          beforeUpload={(file) => {
            onClear() // Clear existing data first
            onFileUpload(file)
            return false // Prevent automatic upload
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Choose File</Button>
        </Upload>
      </div>

      {isProcessing && (
        <div className="progress-container">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
            <Progress
              type="circle"
              percent={Math.round(progress)}
              size={80}
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
            />
            {timeRemaining && (
              <div
                style={{
                  fontSize: '14px',
                  color: '#666',
                  marginTop: '-5px',
                }}
              >
                {formatTimeRemaining(timeRemaining)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

FileUpload.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
  progress: PropTypes.number,
  timeRemaining: PropTypes.number,
  formatTimeRemaining: PropTypes.func,
}

FileUpload.defaultProps = {
  isProcessing: false,
  progress: 0,
  timeRemaining: null,
  formatTimeRemaining: (seconds) => `${Math.round(seconds)}s remaining`,
}

export default FileUpload
