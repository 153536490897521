import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Card, Space, Tag, Typography, Skeleton } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  GoldOutlined,
  TeamOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import '../styles/OverrideSummary.scss'

const { Title, Text } = Typography

const renderTypeStats = (loading, stats) => {
  const typeStatsData = [
    {
      icon: <GoldOutlined />,
      label: 'Vendor Location',
      value: stats.byType.vendorLocation,
    },
    {
      icon: <TeamOutlined />,
      label: 'Vendor',
      value: stats.byType.vendor,
    },
    {
      icon: <EnvironmentOutlined />,
      label: 'Location',
      value: stats.byType.location,
    },
  ]

  return typeStatsData.map(({ icon, label, value }, index) => (
    <div className="type-stat" key={index}>
      {loading ? (
        <Skeleton.Button active size="small" style={{ width: 120 }} />
      ) : (
        <>
          <div className="stat-icon">{icon}</div>
          <div className="stat-content">
            <Text>{label}</Text>
            <Text strong>{value}</Text>
          </div>
        </>
      )}
    </div>
  ))
}

const OverrideSummary = ({ overrides, loading }) => {
  const stats = {
    total: 0,
    active: 0,
    inactive: 0,
    byType: {
      location: 0,
      vendor: 0,
      vendorLocation: 0,
    },
  }

  // Calculate statistics
  Object.entries(overrides).forEach(([type, items]) => {
    stats.byType[type] = items.length
    stats.total += items.length
    items.forEach((item) => {
      if (item.status === 'active') {
        stats.active++
      } else {
        stats.inactive++
      }
    })
  })

  return (
    <div className="override-summary">
      <Card className="summary-card">
        <div className="summary-content">
          <div className="summary-header">
            <div className="title-section">
              <Title level={2}>Product Fee Management</Title>
              {loading ? (
                <Skeleton.Input active size="small" style={{ width: 300 }} />
              ) : (
                <Text className="description">
                  Manage your fee and due date overrides for specific vendors and/or locations
                </Text>
              )}
            </div>
            <Text className="total-overrides">{stats.total} Total Overrides</Text>
          </div>
          <div className="summary-details">
            <div className="status-stats">
              <Tag icon={<CheckCircleOutlined />} color="success" className="status-tag">
                {stats.active} Active
              </Tag>
              <Tag icon={<ClockCircleOutlined />} className="status-tag">
                {stats.inactive} Inactive
              </Tag>
            </div>
            <div className="type-stats">
              <Space size="large">{renderTypeStats(loading, stats)}</Space>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

OverrideSummary.propTypes = {
  overrides: PropTypes.shape({
    location: PropTypes.array.isRequired,
    vendor: PropTypes.array.isRequired,
    vendorLocation: PropTypes.array.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
}

OverrideSummary.defaultProps = {
  loading: false,
}

export default memo(OverrideSummary)
