import React, { useEffect } from 'react'
import { Typography, Button, Space, Empty } from 'antd'
import { ArrowLeftOutlined, PlusOutlined, GoldOutlined, TeamOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import OverrideCard from './OverrideCard'
import LoadingSpinner from './LoadingSpinner'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'
import { fetchOverrideSheets } from '../../../store/reducers/feeManagement/feeManagementSlice'
import {
  selectOverrideSheetsGroupedByType,
  selectOverrideSheetsLoading,
  selectOverrideSheetsError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'
import '../styles/ViewOverrideType.scss'
import { useOverrideManagement } from '../hooks/useOverrideManagement'

const { Title } = Typography

const OVERRIDE_TYPE_CONFIG = {
  vendorLocation: {
    title: 'Vendor Location Overrides',
    icon: GoldOutlined,
    description: 'Manage fee and due date overrides for specific vendors at specific locations',
  },
  vendor: {
    title: 'Vendor Overrides',
    icon: TeamOutlined,
    description: 'Manage fee and due date overrides for specific vendors',
  },
  location: {
    title: 'Location Overrides',
    icon: EnvironmentOutlined,
    description: 'Manage fee and due date overrides for specific locations',
  },
}

const ViewOverrideType = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { type } = useParams()

  const overrideSheets = useSelector(selectOverrideSheetsGroupedByType)
  const isLoading = useSelector(selectOverrideSheetsLoading)
  const error = useSelector(selectOverrideSheetsError)

  const { handleAddOverride, handleBack } = useOverrideManagement()

  const overrides = overrideSheets[type] || []
  const typeConfig = OVERRIDE_TYPE_CONFIG[type]
  const Icon = typeConfig?.icon

  useEffect(() => {
    logFormattedMessage('Loading override type view', { type })
    dispatch(fetchOverrideSheets())
  }, [dispatch, type])

  const onBack = () => {
    logFormattedMessage('Navigating back to override types', { from: type })
    handleBack()
  }

  const onAddOverride = () => {
    logFormattedMessage('Navigating to create override', { type })
    handleAddOverride(type)
  }

  const handleSelectOverride = (override) => {
    logFormattedMessage('Navigating to view override', { type, overrideId: override.id })
    history.push(`/fee-management/override/${override.id}`)
  }

  if (!typeConfig) {
    return <div>Invalid override type</div>
  }

  if (error) {
    return (
      <div className="view-override-type fee-management-container">
        <div className="header-section">
          <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={onBack} />
          <div>Error loading overrides: {error}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="view-override-type fee-management-container">
      <div className="header-section">
        <Space size="middle" align="center">
          <Button type="text" className="back-button" icon={<ArrowLeftOutlined />} onClick={onBack} />
          <Title level={3} style={{ margin: 0 }}>
            <Space>
              <Icon />
              {typeConfig.title}
            </Space>
          </Title>
        </Space>
        <Button type="primary" className="add-override-button" icon={<PlusOutlined />} onClick={onAddOverride}>
          Add Override
        </Button>
      </div>

      <div className="overrides-list">
        {isLoading ? (
          <LoadingSpinner text={`Loading ${typeConfig.title.toLowerCase()}...`} />
        ) : overrides.length > 0 ? (
          overrides.map((override) => (
            <OverrideCard
              key={override.id}
              title={override.title}
              status={override.status}
              details={override.details}
              overriddenFields={override.overriddenFields}
              onSelect={() => handleSelectOverride(override)}
            />
          ))
        ) : (
          <Empty
            description={
              <Space direction="vertical" align="center">
                <span>No overrides found</span>
                <span className="text-secondary">{typeConfig.description}</span>
              </Space>
            }
          >
            <Button type="primary" icon={<PlusOutlined />} onClick={onAddOverride}>
              Create First Override
            </Button>
          </Empty>
        )}
      </div>
    </div>
  )
}

export default ViewOverrideType
