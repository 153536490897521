import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import './styles/ValidationPills.scss'

const ValidationPill = ({ label, count, color, icon: Icon, tooltip, isSelected, onClick }) => {
  return (
    <Tooltip title={tooltip}>
      <div className={`validation-pill ${isSelected ? 'selected' : ''}`} onClick={onClick}>
        <Icon style={{ color, fontSize: '14px' }} />
        <span className="label">{label}</span>
        <span className="count">{count}</span>
      </div>
    </Tooltip>
  )
}

ValidationPill.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  tooltip: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const GridControlsValidationPills = ({
  validationResults,
  selectedErrorTypes,
  onToggleErrorType,
  validationConfig,
}) => {
  return (
    <div className="validation-pills">
      {Object.entries(validationConfig).map(([errorType, config]) => (
        <ValidationPill
          key={errorType}
          label={config.label}
          count={validationResults[errorType]}
          color={config.color}
          icon={config.icon}
          tooltip={`${validationResults[errorType]} ${config.tooltipText}`}
          isSelected={selectedErrorTypes.has(errorType)}
          onClick={() => onToggleErrorType(errorType)}
        />
      ))}
    </div>
  )
}

GridControlsValidationPills.propTypes = {
  validationResults: PropTypes.objectOf(PropTypes.number).isRequired,
  selectedErrorTypes: PropTypes.instanceOf(Set).isRequired,
  onToggleErrorType: PropTypes.func.isRequired,
  validationConfig: PropTypes.object.isRequired,
}

export default GridControlsValidationPills
