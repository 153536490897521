import React, { memo, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { Button, Layout } from 'antd'
import Grid from '../Grid'
import GridControlsCardContainer from '../GridControlsCardContainer'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'
import useGridColumns from '../hooks/useGridColumns'
import { useGridState } from '../hooks/useGridState'
import { FeeValidationErrorTypes } from '../constants/Validation'
import '../styles/Grid.scss'
import '../styles/GridContainer.scss'

const { Sider, Content } = Layout

const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.totalErrors === nextProps.totalErrors &&
    prevProps.onSave === nextProps.onSave &&
    JSON.stringify(prevProps.rowData) === JSON.stringify(nextProps.rowData)
  )
}

const GridManager = ({ initialRowData, validationSummary, validationConfig, onSave, totalErrors }) => {
  const {
    gridProps,
    activeFilters,
    gridApiRef,
    isSummaryExpanded,
    handleToggleDelete,
    handleCellValueChanged,
    handleFilterChanged,
    handleFilteredRowsChange,
    handleRemoveFilter,
    handleToggleExpand,
    handleValidationFilterChange,
    setGridProps,
  } = useGridState()

  const displayData = useMemo(() => {
    return gridProps?.rowData || initialRowData
  }, [gridProps?.rowData, initialRowData])

  const { columnDefs, defaultColDef } = useGridColumns({
    rowData: displayData,
    headerDeleteActive: false,
  })

  const context = useMemo(
    () => ({
      onToggleDelete: handleToggleDelete,
    }),
    [handleToggleDelete],
  )

  useEffect(() => {
    logFormattedMessage('GridManager initializing gridProps', {
      hasRowData: !!initialRowData,
      rowCount: initialRowData?.length,
    })

    if (initialRowData) {
      setGridProps({
        rowData: initialRowData,
      })
    }
  }, [initialRowData, setGridProps])

  logFormattedMessage('GridManager rendering', {
    hasRowData: !!displayData,
    rowCount: displayData?.length,
    filteredRowCount: gridProps?.rowData?.length,
    totalErrors,
    sampleRow: displayData?.[0],
    columnDefs,
  })

  if (!displayData) {
    logFormattedMessage('GridManager missing required data', {
      hasRowData: !!initialRowData,
      hasGridProps: !!gridProps,
    })
    return null
  }

  return (
    <>
      <Row className="mb-4">
        <Col className="d-flex justify-content-end">
          <Button type="primary" onClick={onSave} disabled={totalErrors > 0}>
            Save Changes
          </Button>
        </Col>
      </Row>
      <Layout className="content-layout">
        <Sider
          width={400}
          collapsible
          collapsed={!isSummaryExpanded}
          onCollapse={(collapsed) => handleToggleExpand(!collapsed)}
          trigger={null}
          className="summary-sider"
          collapsedWidth={100}
        >
          <GridControlsCardContainer
            isExpanded={isSummaryExpanded}
            onToggleExpand={handleToggleExpand}
            rowData={initialRowData}
            validationSummary={validationSummary}
            onFilteredRowsChange={handleFilteredRowsChange}
            activeFilters={activeFilters}
            onRemoveFilter={handleRemoveFilter}
            validationConfig={validationConfig}
            validationErrorTypes={FeeValidationErrorTypes}
            onValidationFilterChange={handleValidationFilterChange}
          />
        </Sider>
        <Content className="grid-content">
          <Grid
            rowData={displayData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            context={context}
            gridApiRef={gridApiRef}
            onCellValueChanged={handleCellValueChanged}
            onFilterChanged={handleFilterChanged}
          />
        </Content>
      </Layout>
    </>
  )
}

GridManager.propTypes = {
  initialRowData: PropTypes.array.isRequired,
  validationSummary: PropTypes.object,
  validationConfig: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  totalErrors: PropTypes.number.isRequired,
}

export default memo(GridManager, arePropsEqual)
